import { createSlice } from '@reduxjs/toolkit';

const bytesSlice = createSlice({
    name: 'bytes',
    initialState: {
        bytes: [],
        status: 'idle',
        isLoading: false,
        error: null
    },
    reducers: {
        setBytes: (state, action) => {
            state.isLoading = false;
            state.bytes = action.payload;
        },
        setByteStatus: (state, action) => {
            state.isLoading = true;
            state.status = action.payload;
        },
        setByteError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { setBytes, setByteStatus, setByteError } = bytesSlice.actions;
export default bytesSlice.reducer;