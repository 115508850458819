
import React, { useState } from 'react';
import LoadingSpinner from '../components/spinner/LoadingSpinner';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function VerificationCodeComponent() {

    const [verificationCode, setVerificationCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const user = useSelector(state => state.profile);

    let email = 'null';

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        email = user.Profile.user.username;
        console.log(email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

    const grabCode = async () => {
        setIsLoading(true); // Start loading
        setVerificationCode(''); // Clear the code initially
    
        try {
            const response = await fetch('https://glamorous-plum-angler.cyclic.app/getVerificationCode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email })  // Assuming you have a state variable named 'email' for the user's email
            });
    
            if (!response.ok) {
                setShowErrorAlert(true);
                setTimeout(() => setShowErrorAlert(false), 2000);
                return;
            }
    
            const data = await response.json();
            if (data.code) {
                const cleanedCode = data.code.replace('-', ''); // Remove the dash from the code
                setVerificationCode(cleanedCode);

            } else {
                setShowErrorAlert(true);
                setTimeout(() => setShowErrorAlert(false), 2000);
            }
        } catch (error) {
            console.error('Failed to fetch:', error);
            setVerificationCode('');
            setShowErrorAlert(true);
            setTimeout(() => setShowErrorAlert(false), 2000); // Clear the code in case of an error
        } finally {
            setIsLoading(false); // Stop loading
        }
    };
    
    
    
    

    // useEffect(() => {
    //     // Fetch the verification code when the component mounts
    //     // Trigger email fetch
    //     //grabCode();
    // }, []);

    const copyToClipboard = async (text) => {
        if (text === '' || null) {return}
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);


        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2000); // hide after 2 seconds
      


    };

    const Verification = () => {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                
                
                <div className={`${showAlert ? 'block' : 'hidden'} fixed bottom-0 right-0 mb-4 mr-4 px-6 py-3 bg-green-500 text-white rounded shadow-lg sm:mb-2 sm:mr-2 sm:px-4 sm:py-2`}>
                    Copied to clipboard!
                </div>

                <div className={`${showErrorAlert ? 'block' : 'hidden'} fixed bottom-0 right-0 mb-4 mr-4 px-6 py-3 bg-red-500 text-white rounded shadow-lg sm:mb-2 sm:mr-2 sm:px-4 sm:py-2`}>
                    Error, please try again.
                </div>


                <div className="p-6 lg:p-12 bg-white rounded-xl shadow-lg w-full max-w-xs md:max-w-md lg:max-w-xl">
                    <h1 className="text-3xl md:text-5xl lg:text-6xl font-semibold mb-6 lg:mb-8 text-center">Verification Code</h1>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            
                            <div className="flex justify-center items-center space-x-4 lg:space-x-8 mb-6 lg:mb-8" onClick={() => copyToClipboard(verificationCode)}>
                                <span className="text-4xl md:text-7xl lg:text-8xl font-bold">{verificationCode?.slice(0, 3)}</span>
                                <span className="text-4xl md:text-7xl lg:text-8xl">-</span>
                                <span className="text-4xl md:text-7xl lg:text-8xl font-bold">{verificationCode?.slice(3)}</span>
                            </div>
                        </>
                    )}
                    <button 
                        onClick={grabCode} // Add your refresh function here
                        disabled={isLoading}
                        className={`w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-700 text-xl transition-colors ${isLoading ? 'opacity-50' : ''}`}
                    >
                        Refresh Code
                    </button>
                    
                </div>
            </div>
        );
    };

    return (
        // <div>
        //     {verificationCode === '' && (
        //         <button onClick={grabCode}>Refresh</button>
        //     )}
        //     {clearCode()}
        // </div>
        <Verification />
    );
}



function Verification() {
  return (
    <>
        <VerificationCodeComponent />
    </>
  )
}

export default Verification
