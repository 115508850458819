import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import LoadingSpinner from '../components/spinner/LoadingSpinner';


export default function Page404() {
    let history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //document.title = "404 | Page Not Found";
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);




    return (
        <main className="h-screen w-full flex flex-col justify-center items-center bg-[#1A2238]">
            {loading ? <LoadingSpinner /> : 
            <div className='flex flex-col justify-center items-center'>
                <h1 className="text-9xl font-extrabold text-white tracking-widest">404</h1>
                <div className="bg-[#FF6A3D] px-2 text-sm rounded rotate-12 absolute">
                    Page Not Found
                </div>
                <button className="mt-5">
                {/* eslint-disable-next-line */}
                <a
                    className="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring"
                >
                    <span
                    className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#FF6A3D] group-hover:translate-y-0 group-hover:translate-x-0"
                    ></span>

                    <span className="relative block px-8 py-3 bg-[#1A2238] border border-current">
                    <button onClick={() => {history.goBack()}}>Go Back</button>
                    
                    </span>
                </a>
                </button>
            </div>
        }         
        </main>
    )
}
