import React from 'react';
import Homepage from './pages/Homepage';
import CoursePage from './pages/CoursePage';
import StudentDashboard from './pages/StudentDashboard';
import MainTemplate from './components/main/MainTemplate';
import Page404 from './pages/Page404';
import Verification from './pages/Verification';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Assignments from './pages/Assignments';
import AssignmentDetail from './pages/Assignment';
import Calendar from './functions/Calender';
import Landing from './pages/Landing';
import 'intersection-observer';


function App() {
    return(
      <Provider store={store}>
        <Router>
          <Switch>

              <Route path='/' exact
                render={() => <MainTemplate authNeeded={false} PageContent={() => <Landing />} />}
              />

              <Route path='/courses' exact
                render={() => <MainTemplate PageContent={() => <Homepage />} />}
              />

              <Route path='/courses/:courseId' exact
                render={() => <MainTemplate PageContent={(getBytes, getAssignments, signin, setIsAuthenticated, markAsComplete) => <CoursePage getBytes={getBytes} getAssignments={getAssignments}/>} />}
              />
            
              <Route path='/my-assignments' exact
                render={() => <MainTemplate PageContent={() => <Assignments />} />}
              />



              <Route path='/home' exact 
                render={() => <MainTemplate route="home" PageContent={() => <StudentDashboard />} />}
              />
            
              <Route path='/assignment/:assignmentId' exact 
                render={() => <MainTemplate PageContent={(getBytes, getAssignments, signin, setIsAuthenticated, markAsComplete) => <AssignmentDetail getAssignments={getAssignments} markAsComplete={markAsComplete} />} />}
              />

              <Route path='/verification' 
                render={() => <MainTemplate PageContent={() => <Verification />} />}
              />

              <Route path='/login' 
                render={() => <MainTemplate route="login" authNeeded={false} PageContent={(getBytes, getAssignments, signin, setIsAuthenticated, markAsComplete) => <Login signin={signin} />} />}
              />
            
              <Route path='/logout' 
                render={() => <MainTemplate authNeeded={false} PageContent={(getBytes, getAssignments, signin, setIsAuthenticated, markAsComplete) => <Logout setIsAuthenticated={setIsAuthenticated} />} />}
            />
            
              <Route path='/calendar' exact
                    render={() => <MainTemplate PageContent={() => <Calendar />} />}
              />

              <Route path='*' 
                    render={() => <MainTemplate authNeeded={false} route="404" PageContent={() => <Page404 />} />}
              />


            </Switch>
        </Router>
      </Provider>
    );
}

export default App;
