export default function formatDate (dateString, submitssion = false) {
    if (!dateString) {
        if (!submitssion) {
            return "Not Due";
        } else if (submitssion) {
            return "Unknown";
        }
    }

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    return utcDate.toLocaleDateString(undefined, options);
}