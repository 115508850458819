import React, { useState, useEffect } from 'react'
import { FaList, FaSignInAlt } from 'react-icons/fa'
import logo from '../../images/try.png'

import { Link } from 'react-router-dom';

export default function MobileHeader({isOpen, setIsOpen, isAuth, backgroundColor}) {
  const [isHeaderVisible, setHeaderVisible] = useState(true);

  const handleScroll = debounce(() => {
    if (window.scrollY > 50) {  // adjust this value as needed
      setHeaderVisible(false);
    } else {
      setHeaderVisible(true);
    }
  }, 100);  // 100ms debounce time

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }
  

  return (
    <>
      <div 
        className={`h-16 flex items-center justify-center transition-transform duration-300 ${isHeaderVisible ? 'translate-y-0' : '-translate-y-full'}`} 
        style={{ fontFamily: 'Arvo', transform: isHeaderVisible ? 'translateY(0)' : 'translateY(-100%)', position: 'absolute', width: '100%'}}
      >
        <div className='h-16 bg-gray-700 flex items-center justify-center flex-1' style={{fontFamily: 'Arvo'}}>  
              
              <div className={`flex-1 ${!isAuth && 'invisible'}`}>
                  <button onClick={() => setIsOpen(!isOpen)} className='text-white scale-150 ml-5 '>
                      <FaList />
                  </button>
              </div> 
  
            <div className='flex space-x-2 center items-center'>
                
              <img src={logo} alt="" className='w-10 h-10 scale-hover-10' />
              <Link to={`${isAuth ? '/home' : '/'}`}>
                <h1 className=' text-white font-bold text-2xl'>ByteSized</h1>
              </Link>
        
          </div>
  
          {/* Login Icon */}
          <div className='flex-1 flex justify-end'>
            {!isAuth && (
              <Link to="/login" className="mr-5 text-white opacity-80 hover:text-blue-400 transition">
                <FaSignInAlt size={24} />
              </Link>
            )}
          </div>
            
          </div>
      </div>
      <div className="h-16"></div> {/* This is the spacer */}
      <button 
        onClick={() => setIsOpen(true)} 
        className={`fixed top-4 z-40 -left-5 text-white scale-150 ml-5 bg-gray-700 p-2 rounded-r-lg transition-all duration-300 ${!isAuth && 'invisible'}`}
        style={{ scale: '115%',transform: isHeaderVisible ? 'translateX(-100%)' : 'translateX(0)', opacity: isHeaderVisible ? 0 : 1 }}
      >
        <FaList />
      </button>
    </>
  );
}
