import { createSlice } from '@reduxjs/toolkit';

const ProfileSlice = createSlice({
    name: 'profile',
    initialState: {
        Profile: [],
        status: 'idle',
        isLoading: false,
        error: null
    },
    reducers: {
        setProfile: (state, action) => {
            state.isLoading = false;
            state.Profile = action.payload;
        },
        setStatus: (state, action) => {
            state.isLoading = true;
            state.status = action.payload;
        },
        setError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearProfile: (state) => {
            state.isLoading = false;
            state.Profile = {};  // or {}
            state.status = {};   // or initial status value
            state.error = {};    // or initial error value
        }
        
    }
});

export const { setProfile, setStatus, setError, clearProfile } = ProfileSlice.actions;
export default ProfileSlice.reducer;