import React, {useEffect, useState, useRef} from 'react';
import { useParams, useHistory  } from 'react-router-dom';
import { FaVideo, FaLink, FaClipboard, FaArrowRight, FaInfoCircle, FaExclamationTriangle, FaQuestionCircle } from 'react-icons/fa'; // You can use any icon set, I'm using Font Awesome for this example
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import LoadingSpinner from '../components/spinner/LoadingSpinner';
import Confetti from 'react-confetti';
import { turnInAssignment } from '../redux/reducers/assignmentSlice';
import formatDate from '../functions/formatDate';
import { FaArrowCircleLeft } from 'react-icons/fa';


const gradeText = (grade) => {
    if (grade >= 95) {
        return "text-green-500";
      } else if (grade >= 80) {
        return "text-blue-500";
      } else if (grade >= 70) {
        return "text-yellow-500";
      } else if (grade >= 60) {
        return "text-yellow-700";
      } else if (grade >= 0) {
        return "text-red-500";
      }
      else if (grade === -1) {
        return "text-gray-500";
      } else {
        return "text-gray-500";
      }
      }


    




const AssignmentDetail = ({getAssignments, markAsComplete}) => {
    const { assignmentId } = useParams();
    const history = useHistory();
    const All_Assignments = useSelector(state => state.assignments);
    const [assignment, setAssignment] = useState(null);
    const [Submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();
    const ModalRef = useRef(null);
    const [showModal, setShowModal] = useState(false);

    

    const handleTurnInClick = () => {
        let scrollInterval;
        setShowModal(true);
        const scrollToCurrentDate = () => {
            if (ModalRef.current) {
                const scrollToCurrentDate = () => {
                    if (ModalRef.current) {
                        ModalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        clearInterval(scrollInterval);
                    }
                };
                scrollToCurrentDate();
                clearInterval(scrollInterval);
            }
        };
    
        scrollInterval = setInterval(scrollToCurrentDate, 0);
    };

    const handleConfirmTurnIn = () => {
        // Close the modal immediately to provide feedback to the user
        setShowModal(false);

        // Check if the assignment exists and hasn't been turned in yet
        if (assignment && !assignment.turned_in) {
            // Implement your logic to actually "Turn In" the assignment
            // ...

            // Mark the assignment as complete
            
            setSubmitted(true);
            markAsComplete(assignmentId);
            setAssignment(prevAssignment => ({ ...prevAssignment, turned_in: true }));

            // Scroll to the top of the page
        } else {
            console.warn("Assignment either doesn't exist or has already been turned in.");
        }
    };

    const [showConfetti, setShowConfetti] = useState(false);
    const [confettiOpacity, setConfettiOpacity] = useState(1);
    
    useEffect(() => {
        let fadeOutTimer, removeTimer;
        if (Submitted) {
            dispatch(turnInAssignment({ assignmentId }));
            
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setShowConfetti(true);
            setConfettiOpacity(1); // Reset opacity to 1 when showing confetti
    
            // After a certain duration (e.g., 7 seconds), start fading out the confetti
            fadeOutTimer = setTimeout(() => {
                setConfettiOpacity(0);
            }, 5000);
    
            // After fade out, stop rendering confetti
            removeTimer = setTimeout(() => {
                setShowConfetti(false);
            }, 9000);

            


            
        }
    
        return () => {
            clearTimeout(fadeOutTimer);
            clearTimeout(removeTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Submitted]);

    const celibrate = () => { 
        if (assignment.grade === "A") { 
            setSubmitted(true);
        }
    }
    


    useEffect(() => {
        const fetchAssignments = async () => {
            await getAssignments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fetchAssignments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getCurrentAssignment = () => {
        if (All_Assignments && assignmentId) {
            const foundAssignment = All_Assignments.find(assignment => assignment.id === assignmentId);
            if (foundAssignment) {
                setAssignment(foundAssignment);
            } else {
                history.push('/my-assignments');
            }
        } else {
            history.push('/my-assignments');
        }
     }

    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getCurrentAssignment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [All_Assignments, assignmentId, history]);





    const [videos, setVideos] = useState([])
    const [urls, setUrls] = useState([])
    const [quizzes, setQuizzes] = useState([])


    useEffect(() => {

        if (assignment) {
            if (assignment.locked) {
                history.push('/my-assignments');
             }
        }
        setVideos([]); // Empty the video list
        setUrls([]);   // Empty the URL list
        setQuizzes([]);   // Empty the Quizzes list
        if (assignment && assignment.assignment_instance && assignment.assignment_instance.template) {
            const resources = assignment.assignment_instance.template.resources; // corrected the typo
            if (resources && resources.length) {
                
                for (let resource of resources) {
                    if (resource.resource_type === 'video') {
                        setVideos(prevVideos => [...prevVideos, resource]);


                    } else if (resource.resource_type === 'link') {
                        setUrls(prevUrls => [...prevUrls, resource]);
                        
                    }
                    else if (resource.resource_type === 'quiz') {
                        setQuizzes(prevQuizzes => [...prevQuizzes, resource]);
                        
                    }
                    
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment]);    

    

    return (
        <>
    
            
            {showConfetti && (
                <Confetti className='z-40' style={{ opacity: confettiOpacity, transition: 'opacity 1s', position: 'fixed', zIndex: 30 }} />
            )}
            
            {assignment ? 
            
            <motion.div style={{ fontFamily: 'Arvo' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} className="p-6 w-full max-w-6xl mx-auto mt-10 md:mt-0 rounded-lg relative z-10">
                
                <h1 className='pb-8 font-bold text-5xl flex justify-center text-center text-slate-700' style={{ fontFamily: 'Arvo' }}>{assignment.assignment_instance.template.name}</h1>            
                    <motion.div initial={{ scale: 0.9 }} animate={{ scale: 1 }} transition={{ duration: 0.5 }} className=" p-6 rounded-2xl shadow-lg ">
                    <button onClick={history.goBack} className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-l shadow">
                        <FaArrowCircleLeft /> &nbsp;
                        Back
                    </button>
                    <br />
                    <p style={{ fontFamily: 'Arvo' }} className="text-xl md:text-2xl  font-bold mb-4 text-slate-700 ">Due Date: {formatDate(assignment.assignment_instance.due_date)}</p>
                    
                    {assignment.turned_in && 
                        <p style={{ fontFamily: 'Arvo' }} className="text-xl md:text-2xl  font-bold mb-4 text-slate-700 ">Submited: {formatDate(assignment.submission_date, true)}</p>
                    }
                    
                    <div className="mb-6 flex justify-between items-center">
                        {assignment.turned_in ? (
                            <>
                                <span style={{ fontFamily: 'Arvo' }}  className="text-green-500 font-bold text-2xl">Turned In { assignment.grade !== -1 ? <span>+ Graded</span>: null }</span>
                                <span style={{ fontFamily: 'Arvo' }}  className={assignment.grade === -1 ? "text-orange-500 font-medium text-xl" : "font-bold text-xl text-center"} >{assignment.grade === -1 ? "Awaiting Grade" : <>Grade: <span onClick={celibrate} className={`${gradeText(assignment.grade)} scale-hover-10`}>{assignment.grade}</span>%</>}</span>
                            </>
                        ) : (
                            <motion.button whileHover={{ scale: 1.05 }} onClick={handleTurnInClick} className="text-white bg-red-600 px-5 py-3 rounded-lg shadow-md font-medium text-xl">Turn In</motion.button>
                        )}
                        </div>

                    <div className="mt-8 border-t border-gray-300 pt-6">
                        <h2 className="text-2xl font-bold flex items-center mb-4 text-slate-700">
                            <FaClipboard className="mr-3 text-green-600" />
                            Assignment Instructions
                        </h2>

                        {/* Steps Section */}
                        <div className="mt-4">
                            <h3 className="text-xl font-medium flex items-center mb-3 text-slate-600">
                                <FaArrowRight className="mr-2 text-orange-600" />
                                Steps
                            </h3>
                            <ul style={{ listStyleType: 'none', paddingLeft: '0.5rem', marker: 'none' }}>
                                {assignment.assignment_instance.template.steps.map((step, index) => (
                                    <li key={index} style={{ 
                                        color: 'gray', 
                                        position: 'relative', 
                                        paddingLeft: '2.25rem',
                                        // paddingBottom: '0.5rem',
                                        marginBottom: '0.5rem',
                                        fontSize: '1.155rem',
                                        overflowWrap: 'break-word',
                                        marker: 'none',
                                    }}>
                                        <span style={{ 
                                            content: '""', 
                                            position: 'absolute', 
                                            left: 0, 
                                            top: '50%', 
                                            transform: 'translateY(-50%)', 
                                            width: '16px', 
                                            height: '16px', 
                                            backgroundColor: 'rgb(51, 65, 85)', 
                                            borderRadius: '50%'
                                        }}></span>
                                        {assignment.turned_in ? <del>{step.step_instruction}</del> : step.step_instruction}
                                    </li>
                                ))}
                            </ul>
                            {assignment.assignment_instance.template.steps.length === 0 && <p className='opacity-40'>Oh no! Looks to be no steps here..</p>}
                        </div>



                        {/* Instructions Section */}
                        <div className="mt-6">
                            <h3 className="text-xl font-medium flex items-center mb-3 text-slate-600">
                                <FaInfoCircle className="mr-2 text-teal-600" />
                                Instructions
                            </h3>
                                <div className="text-gray-700" style={{ overflowWrap: 'break-word' }}>{assignment.assignment_instance.template.instructions ? <div dangerouslySetInnerHTML={{ __html: assignment.assignment_instance.template.instructions }} /> : <p className='opacity-40'>No instructions for this...</p>} </div>
                                
                            </div>

                        {/* Extra Information Section */}
                        <div className="mt-6">
                            <h3 className="text-xl font-medium flex items-center mb-3 text-slate-600">
                                <FaExclamationTriangle className="mr-2 text-yellow-600" />
                                Extra Information
                            </h3>
                                <div className="text-gray-700" style={{ overflowWrap: 'break-word' }}>{ assignment.assignment_instance.template.extra_information || <p className='opacity-40'>No extra information on this</p>}</div>
                        </div>
                    </div>
        
                    <div className="mt-8 border-t border-gray-300 pt-6">
                    <h2 className="text-2xl font-bold flex items-center mb-4 text-slate-700">
                        <FaVideo className="mr-3 text-blue-600" />
                        Videos
                    </h2>
                    {videos.length > 0 ? (
                        <ul className="space-y-3">
                            {videos.map((video, index) => {
                                // Extract YouTube video ID
                                const videoIdMatch = video.resource_url.match(/(?:v=|\/)([0-9A-Za-z_-]{10}[048AEIMQUYcgkosw])/);
                                const videoId = videoIdMatch ? videoIdMatch[1] : null;

                                return (
                                    <li key={index} className="my-1">
                                        {videoId ? (
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <iframe 
                                                    width="560" 
                                                    height="315" 
                                                    src={`https://www.youtube.com/embed/${videoId}`}
                                                    title="Video"
                                                    frameBorder="0" 
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                    allowFullScreen
                                                    onError={(e) => {
                                                        // If the iframe fails to load, replace it with the link
                                                        e.target.replaceWith(
                                                            <a href={video.resource_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline font-medium">{ video.resource_name || <p>VIDEO LINK</p> }</a>
                                                        );
                                                    }}
                                                    style={{zIndex: 1}}
                                                />
                                            </div>
                                        ) : (
                                                <a href={video.resource_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline font-medium">{ video.resource_name || <p>VIDEO LINK</p> }</a>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <p className="text-gray-500 text-lg opacity-40">No videos are linked for this assignment.</p>
                    )}
                </div>
                        

                        {quizzes.length > 0 &&
                            <div className="mt-8 border-t border-gray-300 pt-6">
                                <h2 className="text-2xl font-bold flex items-center mb-4 text-slate-700">
                                    <FaQuestionCircle className="mr-3 text-blue-600" />
                                    Test
                                </h2>
                                {quizzes.length > 0 ? (
                                    <ul className="space-y-3">
                                        {quizzes.map((quiz, index) => {
                                            return (
                                                // eslint-disable-next-line react-hooks/exhaustive-deps
                                                <li key={index} className="my-1">
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {/* eslint-disable-next-line */}
                                                        <iframe src={quiz.resource_url} width="640" height="1000" frameborder="0" marginheight="0" marginwidth="0"
                                                        onError={(e) => {
                                                            // If the iframe fails to load, replace it with the link
                                                            e.target.replaceWith(
                                                                <a href={quiz.resource_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline font-medium">{ quiz.resource_name || <p>VIDEO LINK</p> }</a>
                                                            );
                                                        }}>Loading…</iframe>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500 text-lg opacity-40">No Quizes/Tests are linked for this assignment.</p>
                                )}
                            </div>
                        }








                <div className="mt-8 border-t border-gray-300 pt-6">
                    <h2 className="text-2xl font-bold flex items-center mb-4 text-slate-700">
                        <FaLink className="mr-3 text-blue-600" />
                        Resources
                    </h2>
                    {urls.length > 0 ? (
                        <ul className="space-y-3">
                            {urls.map((url, index) => (
                                <li key={index} className="my-1">
                                    <a href={url.resource_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline font-medium">{ url.resource_name || <p>URL TO SITE</p> }</a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-500 text-lg opacity-40">No resources are linked to this assignment.</p>
                    )}
                        </div>
                        
                        {showModal && (
                            <>
                                <div ref={ModalRef} className="absolute w-full h-full flex items-center justify-center z-20" 
                                    style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>

                                    <div className="bg-white rounded-lg p-4 sm:p-6 md:w-3/4 lg:w-1/2 xl:w-1/3">
                                        
                                        <h2 className="text-xl sm:text-2xl font-bold mb-4">Confirm Turn In</h2>
                                        
                                        <p>Are you sure you want to turn in this assignment?</p>
                                        
                                        <div className="mt-4 flex justify-between">
                                            
                                            <button 
                                                onClick={() => setShowModal(false)} 
                                                className="text-gray-500 px-3 py-2 rounded">
                                                Cancel
                                            </button>
                                            
                                            <motion.button whileHover={{ scale: 1.05 }} 
                                                onClick={handleConfirmTurnIn} 
                                                className="text-white bg-green-500 px-4 sm:px-5 py-2 sm:py-3 rounded-lg shadow-md font-medium">
                                                Confirm
                                            </motion.button>
                                        </div>
                                    </div>
                                </div>


                                <div className='bg-black bg-opacity-50 fixed top-0 bottom-0 left-0 right-0 z-10'></div>
                            </>
                    )}
                        
                    

                </motion.div>
                </motion.div>
            : <LoadingSpinner />}
        </>
    );
    
    

}

export default AssignmentDetail;
