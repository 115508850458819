import React from 'react';

function LoadingSpinner() {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
            <div className="loading-spinner"></div>
        </div>
    );
}

export default LoadingSpinner;
