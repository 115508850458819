import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function Login({ signin, setIsAuthenticated, currentPage }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    // eslint-disable-next-line
    const [emailError, setEmailError] = useState(null);
    // eslint-disable-next-line
    const [passwordError, setPasswordError] = useState(null);
    const [displayError, setDisplayError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Clear the previous error
        setError(null);
    
        if (!email.trim()) {
            setError('Email cannot be empty.');
            setDisplayError(true);
            setTimeout(() => setDisplayError(false), 5000);
            return;
        }
    
        if (!password.trim()) {
            setError('Password cannot be empty.');
            setDisplayError(true);
            setTimeout(() => setDisplayError(false), 5000);
            return;
        }
    
        try {
            const response = await signin(email, password);
            
            if (response && response.success) {
                if (setIsAuthenticated) {
                    setIsAuthenticated(true);
                }
            } else if (response && response.message === "HTTP error! Status: 400") {
                setError('Incorrect email or password.'); // Handling the specific server error message
                setDisplayError(true);
                setTimeout(() => setDisplayError(false), 5000);
            } else {
                // For other non-successful responses
                setError('Something happened. Please try again.');
                setDisplayError(true);
                setTimeout(() => setDisplayError(false), 5000);
            }
        } catch (error) {
            console.error("Signin error:", error);
            setError('Something went wrong. Please try again later.');
            setDisplayError(true);
            setTimeout(() => setDisplayError(false), 5000);
        }
    };
    
    
    

    useEffect(() => {
        let errorTimeout;
        if (displayError) {
            errorTimeout = setTimeout(() => setDisplayError(false), 5000);
        }
        
        if (localStorage.getItem('sessionExpired') === 'true') {
            alert('Your session has expired. Please log in again.');
            localStorage.removeItem('sessionExpired');  // Clear the flag
        }

        return () => {
            clearTimeout(errorTimeout);
        };
    }, [displayError]);

    return (
        <>
            <link rel="stylesheet" href="https://kit-pro.fontawesome.com/releases/v5.15.1/css/pro.min.css" />
            
    
            <div className="min-h-screen flex flex-col items-center justify-center w-screen">
                <form onSubmit={handleSubmit}>
                    <div className="min-h-screen flex flex-col items-center justify-center w-screen">
                        <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md lg:w-3/6 w-full relative">
                            {/* Error message */}
                            <AnimatePresence>
                                {displayError && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 50 }}
                                        className="mb-4 px-6 py-3 w-1/2 text-center absolute -top-10 self-center text-white bg-red-500 border border-red-700 rounded-lg shadow-sm z-10" 
                                    >
                                        {error}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">Login To Your Account</div>
                            <div className="relative mt-10 h-px bg-gray-300"></div>
                            <div className="mt-10">
                                <div className={`flex flex-col mb-6 ${emailError ? 'border-red-500' : ''}`}>
                                    <label htmlFor="email" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">E-Mail Address:</label>
                                    <div className="relative">
                                        <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                            <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                            </svg>
                                        </div>
                                        <input 
                                            id="email" 
                                            value={email} 
                                            onChange={(e) => setEmail(e.target.value)} 
                                            type="email" 
                                            name="email" 
                                            className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border ${emailError ? 'border-red-500' : 'border-gray-400'} w-full py-2 focus:outline-none focus:border-blue-400`} 
                                            placeholder="@bytesized.academy" 
                                        />
                                    </div>
                                    {emailError && <span className="text-xs text-red-500 mt-1">This field is required.</span>}
                                </div>
    
                                <div className={`flex flex-col mb-6 ${passwordError ? 'border-red-500' : ''}`}>
                                    <label htmlFor="password" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Password:</label>
                                    <div className="relative">
                                        <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                            <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                            </svg>
                                        </div>
                                        <input 
                                            id="password" 
                                            value={password} 
                                            onChange={(e) => setPassword(e.target.value)} 
                                            type="password" 
                                            name="password" 
                                            className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border ${passwordError ? 'border-red-500' : 'border-gray-400'} w-full py-2 focus:outline-none focus:border-blue-400`} 
                                            placeholder="Password" 
                                        />
                                    </div>
                                    {passwordError && <span className="text-xs text-red-500 mt-1">This field is required.</span>}
                                </div>
    
                                <div className="flex w-full pt-2">
                                    <button type="submit" className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in">
                                        <span className="mr-2 uppercase">Login</span>
                                        <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
    
    
}  
