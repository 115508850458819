import React from 'react'
import Courses from '../components/course/Courses';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../components/spinner/LoadingSpinner';


export default function Homepage() {


  const courses = useSelector(state => state.courses.courses);
  const isLoading = useSelector(state => state.courses.isLoading);



//   useEffect(() => {
//     // Set up the interval
//     const intervalId = setInterval(() => {
//         getassignments();
//     }, 30000); // This will run getAssignments every 10 seconds

//     // Clean up the interval when the component is unmounted
//     return () => clearInterval(intervalId);
// }, [getassignments]); // The effect depends on getAssignments, so it's added to the dependency array


  
    return (
      <div className='row' style={{ width: "100%", maxWidth: "950px" }}>
  
        <div>
          <br />
          <h1 className='font-bold text-5xl flex justify-center text-slate-700' style={{fontFamily: 'Arvo'}}>Courses</h1>
          {/* <CourseChips /> */}
        </div>

        <div className="column">
          {isLoading ? <LoadingSpinner /> : <Courses courses={courses} />}
          
        </div>


      </div>
    )
  }
