import { createSlice } from '@reduxjs/toolkit';

const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: [],  // Ensure this is an empty array
    reducers: {
        setMeetings: (state, action) => {
            return action.payload;
        },
    }
});

export const { setMeetings } = meetingsSlice.actions;
export default meetingsSlice.reducer;
