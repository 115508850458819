import React, {useEffect, useState, useRef} from 'react';
import { motion, useAnimation  } from 'framer-motion';
import { FaSearch, FaBook, FaTrophy, FaVideo, FaHandshake, FaSmile, FaComments,FaRocket, FaArrowUp,FaMoneyBillAlt, FaGithub, FaCode, FaUserFriends,FaUserCheck, FaChalkboardTeacher, FaDollarSign, FaLaptopCode, FaCogs, FaServer, FaGlobe, FaFlask, FaTools, FaAngleDown, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';  // Make sure you have `react-icons` installed.
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';



function scrollToSection(thesection) {
  if (thesection.current) {
    thesection.current.scrollIntoView({ behavior: "smooth" });
  }
} 



const Landing = () => {
  gsap.registerPlugin(ScrollTrigger);
  const sectionRef = useRef(null);
  const stepsRef = useRef(null);
   

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }}
      style={{ fontFamily: 'Arvo', backgroundImage: 'radial-gradient(circle, transparent 1%, rgba(255,255,255,0.1) 1%)', backgroundSize: '20px 20px' }}
      className="bg-black bg-opacity-60 text-white min-h-screen w-full"
    >
      {/* <Header /> */}
        <IntroSection stepsRef={stepsRef} />
        <StepsSection stepsRef={stepsRef} />
        <RealWorldProjectsSection />
        <WhyChooseMe />
        <LeadToContact sectionRef={sectionRef} />
        <CoursesSection sectionRef={sectionRef} />
        <FAQSection />
        <Mission />
        <ContactUsSection sectionRef={sectionRef} />
      
        {/* <Footer /> */}
    </motion.div>
  );
}





const IntroSection = ({stepsRef}) => {
    const titleVariant = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 0.6 } }
    };

    const textVariant = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.4, duration: 0.6 } }
    };

    return (
        <motion.section 
            className="relative flex flex-col items-center justify-center pt-16 pb-8 bg-black text-white bg-opacity-60 bg-pattern"
            initial="hidden"
            animate="visible"
            style={{
                backgroundImage: 'radial-gradient(circle, transparent 1%, rgba(255,255,255,0.1) 1%), url(path_to_your_subtle_background_image)',
                backgroundSize: '20px 20px'
            }}
        >
            {/* Curly Braces */}
            {/* <div className="hidden sm:block absolute transform -translate-y-1/2 left-2 sm:left-4 text-6xl sm:text-9xl text-white opacity-20">{`{`}</div>
            <div className="hidden sm:block absolute transform -translate-y-1/2 right-2 sm:right-4 text-6xl sm:text-9xl text-white opacity-20">{`}`}</div>
             */}
            <motion.h1 
                variants={titleVariant}
                className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl mb-4 font-extrabold tracking-tight leading-tight text-center"
            >
                <span className="bg-clip-text text-transparent bg-gradient-to-r to-blue-400 from-green-500">
                  
                  <span className="text-5xl sm:text-9xl text-white opacity-20 ">{`{`}</span>
                      &nbsp;ByteSized&nbsp;
                  <span className="text-5xl sm:text-9xl text-white opacity-20">{`}`}</span>
            
                </span>
            </motion.h1>

            <motion.p 
                variants={textVariant}
                className="max-w-2xl mb-6 text-xl ml-4 mr-4 leading-relaxed text-center"
                style={{overflowWrap: 'break-word'}}
            >
                Discover advanced programming concepts in an engaging and immersive environment. Dive deeper than ever before.
            </motion.p>

            {/* <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-stretch">
                <motion.input 
                    className="w-full sm:w-auto px-4 py-2 text-black placeholder-gray-500 bg-white border border-gray-300 rounded focus:outline-none shadow-sm transition-shadow duration-300 hover:shadow-md focus:shadow-md"
                    type="email" 
                    placeholder="Email address"
                />
                <motion.button 
                    whileHover={{ scale: 1.03, boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)' }}
                    whileTap={{ scale: 0.97 }} 
                    className="w-full sm:w-auto px-6 py-2 font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition-colors duration-300 rounded shadow-sm"
                >
                    Enroll Now
                </motion.button>
            </div> */}






              <motion.button 
                whileHover={{ y: -3 }}
                whileTap={{ y: 1 }}
                onClick={() => scrollToSection(stepsRef)}
                className="mt-4 px-6 py-2 font-semibold text-black bg-white hover:bg-gray-100 transition-colors duration-300 rounded"
              >
                Learn More
            </motion.button>

            <motion.div
                className="mt-16 w-1/2 h-1 bg-blue-600"
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ duration: 1 }}
            ></motion.div>
        </motion.section>
    );
}


// const StepsSection = () => {
//   const steps = [
//       { icon: <FaSearch />, title: 'Discover', description: "Discover what you would like to learn and create." },
//       { icon: <FaVideo />, title: 'Discuss', description: "Discuss pricing and class occurrences, with student and parent schudles always taken into consdieration." },
//       { icon: <FaHandshake />, title: 'Approve', description: 'Once a learning path has been created it can be approved by all partys.' },
//       { icon: <FaBook />, title: 'Learn', description: 'Engage with our comprehensive courses and interactive lessons, paired with our one-on-one classes.' },
//       { icon: <FaTrophy />, title: 'Achieve', description: 'Master new skills, create amazing projects, and achieve your coding goals.' },
//   ];

//   const useIntersectionObserver = (ref) => {
//       const [isVisible, setIsVisible] = useState(false);
      
//       useEffect(() => {
//           const observer = new IntersectionObserver(([entry]) => {
//               setIsVisible(entry.isIntersecting);
//           });

//           if (ref.current) {
//               observer.observe(ref.current);
//           }

//           return () => {
//               if (ref.current) {
//                   observer.unobserve(ref.current);
//               }
//           };
//       }, [ref]);

//       return isVisible;
//   };

//   const ref = useRef(null);
//   const isVisible = useIntersectionObserver(ref);
//   const controls = useAnimation();

//   useEffect(() => {
//       if (isVisible) {
//           controls.start({ opacity: 1, y: 0 });
//       }
//   }, [controls, isVisible]);

//   return (
//       <motion.section 
//           className="py-12 bg-black text-white"
//           style={{
//               backgroundImage: 'radial-gradient(circle, transparent 1%, rgba(255,255,255,0.1) 1%)',
//               backgroundSize: '20px 20px'
//           }}
//       >
//           <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//               <div className="text-center mb-12">
//                   <h2 className="text-base text-green-400 font-semibold tracking-wide uppercase">Steps to Success</h2>
//                   <p className="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl lg:text-5xl">Easy Steps To Begin</p>
//               </div>

//               <div className="space-y-12 lg:space-y-16">
//                   {steps.map((step, index) => {
                      

//                       return (
//                           <motion.div
//                               ref={ref}
//                               key={index}
//                               initial={{ opacity: 0, y: 50 }}
//                               animate={controls}
//                               className="flex flex-col items-center relative"
//                           >
//                               <div className={`relative z-10 w-20 h-20  ${index === steps.length - 1 ? 'bg-gradient-to-r from-gold-start to-gold-end' : 'bg-gradient-to-r to-blue-400 from-green-500'} flex items-center justify-center rounded-full mb-2 shadow-lg transform hover:scale-105 transition-transform duration-300 lg:w-28 lg:h-28`}>
//                                   <span className="text-4xl lg:text-6xl">{step.icon}</span>
//                               </div>
//                               {index === 0 && (
//                                   <div className="absolute w-1 bg-green-400 top-20 center-x z-0 lg:top-28" style={{ height: '2.5rem' }}></div>
//                               )}
//                               {index !== steps.length - 1 && (
//                                   <div className="absolute w-1 bg-green-400 top-full center-x z-0" style={{ height: 'calc(100% + 2.5rem)' }}></div>
//                               )}
//                               {index === steps.length - 1 && (
//                                   <div className="absolute w-1 bg-yellow-400 top-20 center-x z-0 lg:top-28" style={{ height: '2.5rem' }}></div>
//                               )}
//                               <div className="text-center z-10 bg-gray-800 p-6 rounded-lg shadow-lg mt-2 lg:p-8">
//                                   <h3 className="text-xl font-bold mb-2 lg:text-2xl">{step.title}</h3>
//                                   <p className="max-w-xs lg:max-w-md">{step.description}</p>
//                               </div>
//                           </motion.div>
//                       );
//                   })}
//               </div>
//           </div>
//       </motion.section>
//   );
// };


const useIntersectionObserver = (ref, margin = '-50px') => {
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, {
        rootMargin: margin
      });
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
          if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            observer.unobserve(ref.current);
        }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
  
    return isVisible;
  };
  
  const Step = ({ step, index, totalSteps }) => {
    const ref = useRef(null);
    const isVisible = useIntersectionObserver(ref);
    const controls = useAnimation();
  
    useEffect(() => {
      if (isVisible) {
        controls.start({ opacity: 1, y: 0 });
      }
    }, [controls, isVisible]);
  

  return (
      <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          className="flex flex-col items-center relative"
      >
          <div className={`relative z-10 w-20 h-20 ${index === totalSteps - 1 ? 'bg-gradient-to-r from-gold-start to-gold-end' : 'bg-gradient-to-r to-blue-400 from-green-500'} flex items-center justify-center rounded-full mb-2 shadow-lg transform hover:scale-105 transition-transform duration-300 lg:w-28 lg:h-28`}>
              <span className="text-4xl lg:text-6xl">{step.icon}</span>
          </div>
          {index === 0 && (
              <div className="absolute w-1 bg-green-400 top-20 center-x z-0 lg:top-28" style={{height: '2.5rem'}}></div>
          )}
          {index !== totalSteps - 1 && (
              <div className="absolute w-1 bg-green-400 top-full center-x z-0" style={{height: 'calc(100% + 1.5rem)'}}></div>
          )}
          {index === totalSteps - 1 && (
              <div className="absolute w-1 bg-yellow-400 top-20 center-x z-0 lg:top-28" style={{height: '2.5rem'}}></div>
          )}
          <div className="text-center z-10 bg-gray-800 p-6 rounded-lg shadow-lg mt-2 lg:p-8">
              <h3 className="text-xl font-bold mb-2 lg:text-2xl">{step.title}</h3>
              <p className="max-w-xs lg:max-w-md">{step.description}</p>
          </div>
      </motion.div>
  );
};

const StepsSection = ({stepsRef}) => {
  const steps = [
      { icon: <FaSearch />, title: 'Discover', description: "Discover what you would like to learn and create." },
      { icon: <FaVideo />, title: 'Discuss', description: "Discuss pricing and class occurrences, with student and parent schedules always taken into consideration." },
      { icon: <FaMoneyBillAlt /> , title: 'Payment', description: 'After discussing details a personialized quote will be created just for you. If accepted a personilized learning path will be created as well.' },
      { icon: <FaHandshake />, title: 'Approve', description: 'Once a learning path has been created, it can be approved by all parties.' },
      { icon: <FaBook />, title: 'Learn', description: 'Engage with our comprehensive courses and interactive lessons, paired with our one-on-one classes.' },
      { icon: <FaTools />, title: 'Create', description: 'Create amazing projects which have real world implementations and are fun to make!' },
      { icon: <FaTrophy />, title: 'Achieve!', description: 'Master new skills, create amazing projects, and achieve your coding goals!' },
  ];

  return (
      <motion.section
          ref={stepsRef}
          className="py-12 bg-black text-white"
          style={{
              backgroundImage: 'radial-gradient(circle, transparent 1%, rgba(255,255,255,0.1) 1%)',
              backgroundSize: '20px 20px'
          }}
      >
          <div  className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                  <h2 className="text-base text-green-400 font-semibold tracking-wide uppercase">Steps to Success</h2>
                  <p className="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl lg:text-5xl">Easy Steps To Begin</p>
              </div>

              <div className="space-y-12 lg:space-y-16">
                  {steps.map((step, index) => (
                      <Step key={index} step={step} index={index} totalSteps={steps.length} />
                  ))}
              </div>
          </div>
      </motion.section>
  );
};



  
// const RealWorldProjectsSection2 = () => {
//     return (
//         <section className="py-12 bg-black text-white" style={{
//             backgroundImage: 'radial-gradient(circle, transparent 1%, rgba(255,255,255,0.1) 1%)',
//             backgroundSize: '20px 20px'
//         }}>
//             <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//                 <div className="text-center mb-12">
//                     <h2 className="text-base text-green-400 font-semibold tracking-wide uppercase">Real-World Experience</h2>
//                     <p className="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl lg:text-5xl">Bringing Practicality to Learning</p>
//                 </div>
                
//                 <div className="grid lg:grid-cols-2 gap-12">
//                     <div className="space-y-6">
//                         <div className="flex items-center mb-4">
//                             <FaCode className="text-green-400 mr-4 text-6xl"/>
//                             <h3 className="text-2xl font-bold lg:text-3xl">Hands-on Projects</h3>
//                         </div>
//                         <p>Our courses involve real-world projects that replicate challenges in the tech industry. As you learn, you'll build portfolio-worthy applications and tools.</p>
                        
//                         <div className="flex items-center mt-6">
//                             <FaGithub className="text-green-400 mr-4 text-6xl"/>
//                             <h3 className="text-2xl font-bold lg:text-3xl">Using Professional Tools</h3>
//                         </div>
//                         <p>Integrate your learning with tools like GitHub, the platform used by developers worldwide. Learn to collaborate, version-control your code, and showcase your work to potential employers.</p>
                        
//                         <div className="mt-4 flex items-center">
//                             <span className="text-4xl lg:text-6xl"><FaGithub /></span>
//                             <span className="ml-4">See sample projects on our <a href="#" className="text-green-400 underline">GitHub</a></span>
//                         </div>
//                     </div>
                    
//                     <div className="space-y-6">
//                         <div className="flex items-center mb-4">
//                             <FaChalkboardTeacher className="text-green-400 mr-4 text-6xl"/>
//                             <h3 className="text-2xl font-bold lg:text-3xl">One-on-One Tutoring</h3>
//                         </div>
//                         <p>We believe in personalized education. Our one-on-one tutoring sessions ensure that you receive undivided attention, tailored instruction, and instant feedback.</p>
                        
//                         <div className="flex items-center mt-6">
//                             <FaUserFriends className="text-green-400 mr-4 text-6xl"/>
//                             <h3 className="text-2xl font-bold lg:text-3xl">Real-time Collaboration</h3>
//                         </div>
//                         <p>Engage in pair programming sessions, get real-time feedback, and work on projects collaboratively. Experience the dynamics of real-world coding sessions.</p>
                        
                      
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// }




const RealWorldProjectsSection = () => {
  const cardRef = useRef(null);

  const handleMouseMove = (e) => {
    let card = e.currentTarget;
    
    if (!card) return;

    let rect = card.getBoundingClientRect();
    if (!rect) return;

    let halfWidth = card.offsetWidth / 2;
    let halfHeight = card.offsetHeight / 2;
    
    let rotationX = ((e.clientY - rect.top) - halfHeight) / halfHeight * 10;
    let rotationY = ((e.clientX - rect.left) - halfWidth) / halfWidth * 10;

    card.style.transform = `rotateX(${rotationX}deg) rotateY(${rotationY}deg)`;
}

const handleTouchMove = (e) => {
    if (e.touches && e.touches[0]) {
        handleMouseMove(e.touches[0]);
    }
}


  const resetTransform = (e) => {
    e.currentTarget.style.transform = 'rotateX(0) rotateY(0)';
  }

  return (
      <section className="py-12 bg-black text-white" style={{
          backgroundImage: 'radial-gradient(circle, transparent 1%, rgba(255,255,255,0.1) 1%)',
          backgroundSize: '20px 20px'
      }}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                  <h2 className="text-base text-green-400 font-semibold tracking-wide uppercase">Real-World Experience</h2>
                  <p className="text-3xl leading-8 font-bold tracking-tight sm:text-4xl lg:text-5xl">Bringing <span className='text-blue-400 text-extrabold'>Practicality</span> to Learning</p>
              </div>
              
              <div className="grid lg:grid-cols-2 gap-12">
                  <div className="space-y-10 lg:pr-20">
                    <div className="p-6 rounded-lg shadow-lg"
                      onMouseMove={handleMouseMove} 
                        onTouchMove={handleTouchMove}
                        onMouseLeave={resetTransform}
                        ref={cardRef}>
                          <div className="flex items-center mb-4">
                              <FaCode className="text-green-400 mr-4 text-6xl"/>
                              <h3 className="text-2xl font-bold lg:text-3xl">Hands-on Projects</h3>
                          </div>
                          <p>Real-world projects that are not only fun to build but also <span className='text-blue-400'>useful</span>. Create portfolio-worthy applications as you learn.</p>
                      </div>

                        <div className="p-6 rounded-lg shadow-lg"
                          onMouseMove={handleMouseMove} 
                            onTouchMove={handleTouchMove}
                            onMouseLeave={resetTransform}
                            ref={cardRef}>
                          <div className="flex items-center mb-4">
                              <FaGithub className="text-green-400 mr-4 text-6xl"/>
                              <h3 className="text-2xl font-bold lg:text-3xl">Using Professional Tools</h3>
                          </div>
                          <p>Integrate learning with GitHub, the platform used by developers <span className='text-blue-400'>worldwide</span>. Learn to collaborate and version-control your code.</p>
                      </div>
                  </div>
                  
                  <div className="space-y-10 lg:pl-20">
                  <div className="p-6 rounded-lg shadow-lg"
                      onMouseMove={handleMouseMove} 
                        onTouchMove={handleTouchMove}
                        onMouseLeave={resetTransform}
                        ref={cardRef}>
                          <div className="flex items-center mb-4">
                              <FaChalkboardTeacher className="text-green-400 mr-4 text-6xl"/>
                              <h3 className="text-2xl font-bold lg:text-3xl">One-on-One Tutoring</h3>
                          </div>
                          <p>Receive undivided attention, tailored instruction, and instant feedback in our personalized sessions.</p>
                      </div>

                      <div className="p-6 rounded-lg shadow-lg"
                      onMouseMove={handleMouseMove} 
                        onTouchMove={handleTouchMove}
                        onMouseLeave={resetTransform}
                        ref={cardRef}>
                          <div className="flex items-center mb-4">
                              <FaUserFriends className="text-green-400 mr-4 text-6xl"/>
                              <h3 className="text-2xl font-bold lg:text-3xl">Real-time Collaboration</h3>
                          </div>
                          <p>Engage in pair programming sessions and get real-time feedback. Experience the dynamics of real-world coding.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  );
}




// const WhyChooseMe2 = () => {
//   const sectionRef = useRef(null);
//   const [elementsCount, setElementsCount] = useState(0);

//   useEffect(() => {
//       const elements = sectionRef.current.querySelectorAll(".reason");
//       setElementsCount(elements.length);

//       elements.forEach((el) => {
//           gsap.fromTo(el, 
//               { autoAlpha: 0 },
//               { autoAlpha: 1,
//                 scrollTrigger: {
//                   trigger: el,
//                   start: "top+=20% center",  
//                   end: "bottom-=20% center", 
//                   scrub: true,
//                   toggleActions: 'play none none reverse'
//               }
//           });
//       });

//   }, []);

//   return (
//     <div 
//     className='bg-black text-white bg-opacity-60'
//     ref={sectionRef} 
//     style={{ 
//         height: `${100 * elementsCount}vh`, 
//         overflow: 'hidden',
//         backgroundImage: 'radial-gradient(circle, transparent 1%, rgba(255,255,255,0.1) 1%), url(path_to_your_subtle_background_image)',
//         backgroundSize: '20px 20px'
//     }}
// >

//             <div className="reason" style={{ 
//               height: '100vh', 
//               display: 'flex', 
//               alignItems: 'center', 
//               justifyContent: 'center',
//             }}>
//               <Card 
//                 icon={FaReact}
//                 title="React Expertise" 
//                 description="Proficient in building highly optimized React applications."
//               />
//             </div>
//           <div className="reason" style={{ 
//               height: '100vh', 
//               display: 'flex', 
//               alignItems: 'center', 
//               justifyContent: 'center',
//           }}>
//               <Card 
//                     icon={FaReact}
//                     title="React Expertise" 
//                     description="Proficient in building highly optimized React applications."
//                 />
//         </div>
      
//           <div className="reason" style={{ 
//               height: '100vh', 
//               display: 'flex', 
//               alignItems: 'center', 
//               justifyContent: 'center',
//               backgroundImage: 'none',
//           }}>
//               <Card 
//                     icon={FaReact}
//                     title="React Expertise" 
//                     description="Proficient in building highly optimized React applications."
//                 />
//           </div>
      
          
//           {/* Add more reasons as needed */}
//       </div>
//   );
// }




// This is the one actually being used


function WhyChooseMe() {
  const features = [
      {
          title: "Customized Learning",
          description: "Courses tailored to fit individual needs for optimal comprehension, and enjoyment.",
          icon: <FaBook size={50} />,
          bgColor: "bg-gradient-to-t from-blue-800 to-blue-500"
      },
      {
          title: "Relatability",
          description: "Relatable to students while understanding their learning needs, adapting to different learning styles easily",
          icon: <FaUserCheck size={50} />,
          bgColor: "bg-gradient-to-t from-yellow-800 to-yellow-500"
      },
      {
          title: "Affordable",
          description: "Not only are our courses affordable, and flexible, but we also offer a free trial class and a free interview meeting. ",
          icon: <FaDollarSign size={50} />,
          bgColor: "bg-gradient-to-t from-green-800 to-green-500"
      },
      {
          title: "Real Use Cases",
          description: "Instead of learning concepts in isolation, we teach them in the context of real-world applications, building funcitnal tools.",
          icon: <FaLaptopCode size={50} />,
          bgColor: "bg-gradient-to-t from-red-800 to-red-500"
      },
      
    {
        title: "Effective Communication",
        description: "We facilitate open discussions and address student queries, as soon a avaliable. We also provide feedback on student progress.",
        icon: <FaComments size={50} />,
        bgColor: "bg-gradient-to-t from-teal-800 to-teal-500"
    },
    {
        title: "Positive Environment",
        description: "Programming doesn't have to be a boring subject, we make it fun and engaging for all students",
        icon: <FaSmile size={50} />,
        bgColor: "bg-gradient-to-t from-pink-500 to-pink-300"
    },
    {
        title: "Nurturing Relationships",
        description: "We build strong student-teacher relationships for effective learning, and one-on-one tutoring alows for a more personal experience.",
        icon: <FaUserFriends size={50} />,
        bgColor: "bg-gradient-to-t from-purple-800 to-purple-500"
    },

      
      
  ];

  return (
    <div className="p-10">
        <div className="text-center mb-12">
            <h2 className="text-base text-green-400 font-semibold tracking-wide uppercase">Why Bytesized?</h2>
            <p className="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl lg:text-5xl">
                <span className='text-blue-400'>Why</span> should you choose <span className='text-blue-400'>ByteSized?</span>
            </p>
        </div>
      <div className="flex flex-wrap justify-center">
            {features.map((feature, index) => (
                <div key={index} className="m-4 transition-transform transform hover:scale-105">
                    <div className={`w-64 h-full ${feature.bgColor} p-6 pb-8 rounded-xl shadow-lg flex flex-col items-center justify-between`}> {/* Added padding-bottom (pb-8) */}
                        <div 
                            className={`
                                icon-container 
                                text-white 
                                mb-4 
                                transform 
                                transition-transform 

                                ${index === 0 ? 'hover:-translate-y-1' : ''}
                                ${index === 1 ? 'icon-user-friends icon-user-friends:hover' : ''}  
                                ${index === 2 ? 'hover:rotate-45' : ''} 
                                ${index === 3 ? 'hover:-translate-y-1' : ''}
                                ${index === 4 ? 'icon-comments icon-comments:hover' : ''}
                                ${index === 5 ? 'icon-spin icon-spin:hover' : ''}
                                ${index === 6 ? 'icon-user-friends icon-user-friends:hover' : ''}
                            `}>
                                {feature.icon}
                        </div>
                        <div className="text-center flex flex-col justify-center flex-1 ">
                            <h3 className="text-white text-xl">{feature.title}</h3>
                            <p className="text-white">{feature.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

}

function LeadToContact({ sectionRef }) {
  return (
    <div className="py-12 text-center">
      <h2 className="text-4xl text-white font-semibold tracking-wide mb-6">Questions?</h2>
      <p className="text-gray-400 mb-6">Don't hesitate to reach out. Thats what we're here for :)</p>
      <button 
        onClick={() => scrollToSection(sectionRef)} 
        className="py-2 px-6 rounded bg-green-400 hover:bg-green-500 focus:outline-none transition duration-300"
      >
        Contact Us
      </button>
    </div>
  );
}




function DifficultyBadge({ level }) {
  const levels = {
      1: "Beginner",
      2: "Intermediate",
      3: "Advanced",
      4: "Expert",
  };

  const colors = {
      1: "bg-green-400",
      2: "bg-blue-300",
      3: "bg-gradient-to-r from-gold-start to-gold-end",
      4: "bg-red-500",
  };

  return (
      <span className={`text-black font-bold px-3 py-1 rounded-full ${colors[level]}`}>
          {levels[level]}
      </span>
  );
}


function CoursesSection({sectionRef}) {
  const courses = [
      {
        icon: (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <FaFlask />
            &nbsp;+&nbsp;
            <FaCode />
          </span>
        ),
      
          title: "Intro to Computer Science with Programming",
          description: "Delve into the fundamentals of computer science combined with practical programming and fun projects.",
          difficulty: 1
      },
      {
          icon: <FaCogs />,
          title: "Intro to Computer Science with Programming",
          description: "For those looking to take their computer science knowledge to the next level. Build many real and useful projects in an engaging way",
          difficulty: 2
      },
      {
          icon: <FaGlobe />,
          title: "Intro to Web Development",
          description: "Start your journey into the vast world of web development. Learn the basics of HTML and CSS, to create some amazing first websites.",
          difficulty: 2
      },
      {
          icon: <FaServer />,
          title: "Intro to Web Development with Django",
          description: "Master web development with the power of Django, a powerful web framework. Learn to create your own soical media site!",
          difficulty: 3
      }
  ];

  return (
      <section className="py-20 px-10">
          <div className="text-center mb-12">
            <h2 className="text-base text-green-400 font-semibold tracking-wide uppercase">What do we offer?</h2>
            <p className="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl lg:text-5xl">
                <span>What do we teach?</span>
            </p>

          </div>
          <div className="grid grid-cols-1 gap-16 max-w-5xl mx-auto">
              {courses.map((course, index) => (
                  <div key={index} className="flex flex-col items-start justify-between gap-6 border-l-4 border-green-400 pl-6 hover:border-blue-500 transition duration-300">
                      <div className="text-cyan-500 text-6xl">{course.icon}</div>
                      <h3 className="text-3xl text-white font-medium tracking-wide">{course.title}</h3>
                      <DifficultyBadge level={course.difficulty} />
                      <p className="text-gray-400 leading-relaxed">{course.description}</p>
                  </div>
              ))}
      </div>
      {/* Contact Subsection */}
      <div className="mt-20 text-center">
            <h2 className="text-4xl text-white font-semibold tracking-wide mb-6">Interested in more details?</h2>
            <p className="text-gray-400 leading-relaxed mb-8">Get in touch with us to learn more about our courses and how they can benefit you!</p>
            <button onClick={() => scrollToSection(sectionRef)} className="bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-6 rounded-full transition duration-300">
                Contact Us
            </button>
          </div>
      </section>
  );
}



// FAQ Section
function FAQItem({ question, answer, isOpen, onClick }) {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(isOpen ? 'auto' : '0');

  useEffect(() => {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0');
  }, [isOpen]);

  return (
      <div className="border-b border-gray-600 py-4">
          <div className="flex justify-between items-center cursor-pointer" onClick={onClick}>
              <h3 className="text-2xl text-white font-medium tracking-wide">{question}</h3>
              <div 
                  className="text-green-400 text-2xl transform transition-transform duration-300" 
                  style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
              >
                  <FaAngleDown />
              </div>
          </div>
          <div 
              ref={contentRef}
              style={{ 
                  overflow: 'hidden',
                  transition: 'max-height 0.5s ease-in-out',
                  maxHeight: height
              }}
          >
              <p className="mt-2 text-gray-400 leading-relaxed">{answer}</p>
          </div>
      </div>
  );
}

function FAQSection() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
        question: "What is the duration of each course?",
        answer: "Each course typically lasts for 16 - 19 weeks, with weekly assignments and a final project."
    },
    {
        question: "Are there any prerequisites for the courses?",
        answer: "The prerequisites for each course vary. However, a basic understanding of computers and enthusiasm for the subject is always recommended!"
    },
    {
        question: "How much does each course cost?",
        answer: "Prices vary for each course. Please contact us for detailed information."
    },
    {
        question: "What will be taught?",
        answer: "While the actual content of each course varies, we focus on teaching the fundamentals of computer science and programming, with a focus on practicality and real-world applications."
    },
    
    {
    question: "How hard are the courses?",
    answer: "The difficulty of each course comes down to the student. We will work with you to find the right course for you, and help you succeed in it."
    },
    
    {
    question: "How soon can I start? + How do I sign up?",
    answer: "Students can start as soon as their learning path is created. This typicly takes around 1 week. You can sign up by contacting us, and we can shecdule a meeting."
    },
      
  ];

  return (
      <section className="py-8 px-10">
          <div className="text-center mb-12">
              <h2 className="text-base text-green-400 font-semibold tracking-wide uppercase">Frequently Asked Questions</h2>
              <p className="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl lg:text-5xl">
                  <span>Have Questions?</span>
              </p>
          </div>
          <div className="max-w-5xl mx-auto">
              {faqs.map((faq, index) => (
                  <FAQItem
                      key={index}
                      question={faq.question}
                      answer={faq.answer}
                      isOpen={index === openIndex}
                      onClick={() => setOpenIndex(index === openIndex ? null : index)}
                  />
              ))}
          </div>
      </section>
  );
}




// Contact Us Section
function ContactUsSection({ sectionRef }) {
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [consent, setConsent] = useState(false);
    // eslint-disable-next-line
    const [error, setError] = useState({ name: false, email: false, message: false });
    
    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    }
    

    const sendDataToBackend = async (name, email, message) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/enroll/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message
                })
            });
            
            const responseData = await response.json();
            
            if (response.ok) {
                // Handle success
            } else {
                console.error('Failed to send data:', responseData);
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        
        
        // Validation
        let hasError = false;
        if (!name.trim()) {
        setError(prev => ({ ...prev, name: true }));
        hasError = true;
        }
        if (!email.trim()) {
        setError(prev => ({ ...prev, email: true }));
        hasError = true;
        }

        if (!consent) {
            // Handle the error for consent not given.
            hasError = true;
        }

        if (!hasError) {
        // Clear validation errors
        setError({ name: false, email: false, message: false });
            
        setConsent(false);
            
        setName('');
        setEmail('');
        setMessage('');
        
        // Log the form data or make your API call here
        sendDataToBackend(name, email, message);
        scrollToTop();
        }
    };

  
  return (
      <section id={'contactus'} ref={sectionRef} className=" py-20 px-10 text-white">
          <div className="text-center mb-12">
              <h2 className="text-base text-green-400 font-semibold tracking-wide uppercase">Get In Touch</h2>
              <p className="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl lg:text-5xl">
                  Contact Us
              </p>
          </div>
          
         
          <div className="flex flex-col items-center gap-10 md:flex-row md:justify-center md:space-x-10">
            <div className="flex items-center gap-4">
                <FaPhoneAlt className="text-3xl text-green-400" />
                <div>
                    <h3 className="text-xl font-semibold">Phone</h3>
                    <p>+1 (207) 206-9352</p>
                </div>
            </div>
            <div className="flex items-center gap-4">
                <FaEnvelope className="text-3xl text-green-400" />
                <div>
                    <h3 className="text-xl font-semibold">Email</h3>
                    <a className='underline' href="mailto:start@bytesized.academy">start@bytesized.academy</a>
                </div>
            </div>
          </div>

          <div className="mt-16 max-w-lg lg:max-w-3xl mx-auto">
            <h3 className="text-2xl mb-4 font-semibold">Send us a message:</h3>
            <h4 className="mb-4 font-semibold opacity-50">You'll receive a confirmation email afterwards</h4>
            <h4 className="mb-4 font-semibold opacity-70 text-red-600">
                Please check your spam folder if you are unable to find the confirmation email!
            </h4>
            <form onSubmit={(e) => handleSubmit(e)} className="text-center">
                <input 
                value={name}
                onChange={e => setName(e.target.value)}
                type="text"
                placeholder="Name (Required)"
                className="mb-4 p-3 w-full rounded bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:border-green-400" 
                />
                <input 
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="Email (Required)"
                className="mb-4 p-3 w-full rounded bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:border-green-400" 
                />
                <textarea 
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder="Your message (Not Required)"
                rows="4"
                className="mb-4 p-3 w-full rounded bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:border-green-400"
                ></textarea>
                
                {/* Checkbox for consent */}
                <div className="mb-4 text-left">
                    <label className="inline-flex items-center">
                        <input 
                            type="checkbox"
                            checked={consent}
                            onChange={e => setConsent(e.target.checked)}
                        className="form-checkbox rounded text-green-400 focus:ring-green-500 bg-gray-800 border-gray-600"
                        />
                        <span className="ml-2 text-gray-200">I agree to the storage and handling of my data (Including email) by ByteSized.</span>
                    </label>
                </div>

                <button className="py-2 px-6 rounded bg-green-400 hover:bg-green-500 focus:outline-none transition duration-300">
                Submit
                </button>
            </form>
            </div>
      </section>
  );
}


          
          
function Mission() {
  return (
    <section className="  text-white relative">
    <div className="container mx-auto px-4">
        
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">

            <div className="w-full md:w-1/2 relative">
                <div className="bg-gradient-to-br from-green-400 to-blue-500 p-1.5 rounded-xl">
                    <div className="p-4 rounded-xl bg-gray-900 shadow-lg">
                        <FaRocket className="text-9xl mx-auto mb-6" />
                    </div>
                </div>
                <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-green-400 w-16 h-16 rounded-full shadow-lg flex items-center justify-center">
                    <FaArrowUp className="text-gray-900 text-2xl animate-bounce" />
                </div>
            </div>

            <div className="w-full md:w-1/2">
                <h1 className="font-extrabold text-6xl mb-6 text-blue-400 text-center">Our Mission</h1>
                <p className="font-medium text-xl mb-8 text-center">At ByteSized,<br /> we're on a mission
                                                        to redefine the narrative surrounding programming. We believe coding is not just for the select few, but an exhilarating journey of creativity and problem-solving. Our courses are designed to shatter preconceived notions, demonstrating that programming is not a dry, exclusive domain, but a fun, empowering, and universally useful skill. We're here to inspire, engage, and show that every line of code can be a step towards a brighter, more innovative future.</p>
                {/* <button className="py-3 px-6 rounded bg-green-400 hover:bg-green-500 focus:outline-none transition duration-300 transform hover:scale-105" onClick={() => scrollToSection('contactus')}>Learn More</button> */}
            </div>
            
        </div>

    </div>
</section>
  )
}


export default Landing;
