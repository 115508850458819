import { Link, useHistory } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import { FaBookOpen,FaCode, FaCalendarAlt ,FaClipboardList, FaUserEdit,FaUser, FaDatabase, FaArrowAltCircleLeft, FaHome, FaSignOutAlt } from 'react-icons/fa'; 
import { useSelector } from 'react-redux';

function SideBar({ isOpen, setIsOpen, isAuth }) {
    
    const user = useSelector(state => state.profile);
    const [currentUser, setCurrentUser] = useState('')
    const [SuperUser, setSuperUser] = useState('')
    
    const history = useHistory();


    useEffect(() => {
        if (user?.Profile?.user?.is_superuser) {
            setCurrentUser(user);
            setSuperUser(user?.Profile?.user?.is_superuser);
        } else {
            setCurrentUser(null);
            setSuperUser(false);
        }
    }, [user]);

    function isMobile() {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return width < 768; // 768 is the standard width for mobile devices
      }
    
    const sidebarRef = useRef(null);  // Step 1: Create a ref

    useEffect(() => {
        // Step 2: Define the click handler
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                // Step 3: Check if the click was outside the sidebar
                setIsOpen(false);
            }
        };

        // Step 2: Add the event listener
        if (isMobile()) {
            document.addEventListener('mousedown', handleClickOutside);
    
            return () => {
                // Step 4: Cleanup the event listener
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [setIsOpen]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            if (window.innerWidth <= 768) {  // Check if it's mobile
                setIsOpen(false);
            }
        });
    
        return () => {
            unlisten();  // Cleanup the listener on component unmount
        };
    }, [history, setIsOpen]);
    

    function show_back_button () {
        if (isMobile()) {
            return (
                <li className="mb-4">
                    <div onClick={() => setIsOpen(!isOpen)} className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                        <FaArrowAltCircleLeft /> 
                        <span>Back</span>
                    </div>
                </li>
            )
        } else {
            return null;
        }
    }

    return (

        <>

            {/* <button onClick={() => setIsOpen(!isOpen)} className="lg:hidden fixed top-4 left-4 z-50">Menu</button> */}



            <div ref={sidebarRef} className={`z-50 flex flex-col fixed top-0 left-0 overflow-x-hidden h-screen w-64 bg-gray-800 text-white p-4 overflow-y-auto transform transition-transform duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-64'} lg:translate-x-0 md:translate-x-0`}>
                <ul>

                    {show_back_button()}

                    <div className='flex-grow'>
                        <li className="mb-4">
                        <Link to="/home" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                            <FaHome /> 
                            <span>Home</span>
                        </Link>
                        </li>
                        <li className="mb-4">
                        <Link to="/courses" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                            <FaBookOpen /> 
                            <span>Courses</span>
                        </Link>
                        </li>
                        <li className="mb-4">
                        <Link to="/my-assignments" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                            <FaClipboardList /> 
                            <span>Assignments</span>
                        </Link>
                        {/* <ul className="ml-6">
                            <li className="mb-2 text-sm">
                            <span>Assignment 1 - </span>
                            <span className="text-gray-400">Due: 10/10/2023</span>
                            </li>
                            <li className="mb-2 text-sm">
                            <span>Assignment 2 - </span>
                            <span className="text-gray-400">Due: 20/10/2023</span>
                            </li>
                            {/* Add more assignments as needed 
                        </ul> */}
                        </li>

                        <li className="mb-4">
                        <Link to="/calendar" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                            <FaCalendarAlt /> 
                            <span>Calendar</span>
                            </Link>
                        </li>

                        {/* <li className="mb-4">
                        <Link to="/settings" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                            <FaCog /> 
                            <span>Settings</span>
                            </Link>
                        </li> */}

                        
                        

                        

                    
                    
                        <li className="mb-4">
                        <Link to="/verification" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                            <FaCode /> 
                            <span>Verification</span>
                            </Link>
                        </li>  
                    
                        {currentUser && SuperUser ? (
                            <>
                            <li>
                                <a href={`${process.env.REACT_APP_BACKEND_URL}/admin`} className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                                    <FaUserEdit /> 
                                    <span>ADMIN</span>
                                </a>
                            </li>
                                
                            <li>
                                <a href={`${process.env.REACT_APP_BACKEND_SERVER_URL}`} className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                                    <FaDatabase /> 
                                    <span>Backend Server</span>
                                </a>
                            </li>
                                
                            </>
                        ) : null}


                        
                        
                    </div>



                    {!isAuth ? 
                    <li className={`absolute bottom-4 w-64 pr-12 ${isMobile() && 'mb-32'}`}>
                        <Link to="/login" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                            <FaUser /> 
                            <span>Login</span>
                        </Link>
                    </li>
                    :
                    <li className={`absolute bottom-4 w-64 pr-12 ${isMobile() ? 'mb-32':''}`}>
                        <Link to="/logout" className="flex items-center gap-2 hover:bg-gray-700 p-2 rounded">
                            <FaSignOutAlt /> 
                            <span>Logout</span>
                        </Link>
                    </li>
                    }

                </ul>
            </div>
        </>
    );
}

export default SideBar;
