import React, {useEffect, useState} from 'react'
import logo from '../images/OIP.jpg'
import { FaFire } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../components/spinner/LoadingSpinner';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export default function StudentDashboard () {
  const user = useSelector(state => state.profile);
  // eslint-disable-next-line
  const [currentUser, setCurrentUser] = useState('')
  const [studentName, setStudentName] = useState('')
  const [studentProfile, setStudentProfile] = useState('')
  const [SuperUser, setSuperUser] = useState('')
  useEffect(() => {
    if (user && user.Profile && user.Profile.user) {
      setCurrentUser(user);
      setStudentName(user.Profile.user.first_name);
      setSuperUser(user?.Profile?.user?.is_superuser);
      setStudentProfile(user.Profile);
    }
  }, [user]);

  function isSmall() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 756; // 768 is the standard width for mobile devices
  }


  function actionButtonsClasses() {
    if (!isSmall()) {
      return "flex flex-col items-center bg-white p-6 rounded-2xl shadow-md scale-hover-05"
    } else {
      return
    }
  }

  function datesCircel() {
    if (!isSmall()) {
      return (
        <div className="text-green-400 w-16 h-16 rounded-full flex items-center justify-center bg-green-100 p-3">
                {/* Placeholder for an Icon */}
        </div>
      )
    }
  }

  window.addEventListener("resize", () => datesCircel());


  return (
    <>
    {user && studentProfile && user  ? 
      <>
        <div className=" min-h-screen flex flex-col xl:flex-row p-4 md:p-10 lg:w-full">
        
      
          {/* Profile Section */}
          <div className="w-full xl:w-1/3 md:flex-shrink-0 p-4">
            <div className="flex flex-col items-center mb-10 bg-white p-6 rounded-2xl shadow-md scale-hover-05">
              <div className="mb-4 scale-hover-05">
                {/* Placeholder for Profile Image */}

                <div className=" xl:w-56 xl:h-56 lg:w-44 lg:h-44 md:w-24 md:h-24 sm:w-24 sm:h-24">
                    <img src={logo} alt="Description" className="w-full h-full object-center rounded-pill" />
                </div>


              </div>
            <h2 className="text-2xl font-semibold">{studentName ? studentName : <LoadingSpinner />}</h2>
            {/* <p className="text-gray-500 mt-2">Student ID: {currentUser.Profile.id}</p> */}
            </div>
            
            <div className={`${actionButtonsClasses()}`}> {/* SHOULD ONLY BE ON DESKTOP */}
              <div className="grid grid-cols-2 gap-4">
                <button onClick={() => {window.open('https://zoom.com')}} className="text-white bg-blue-500 hover:bg-blue-600 p-2 rounded-full ">
                  {/* Icon placeholder */} Zoom
                </button>
                <button onClick={() => {window.open('https://github.com')}} className="text-white bg-black hover:bg-gray-700 p-2 rounded-full ">
                  {/* Icon placeholder */} GitHub
                </button>
                <button onClick={() => {window.open('https://classroom.github.com')}} className="text-white bg-green-500 hover:bg-green-600 p-2 rounded-full ">
                  {/* Icon placeholder */} Classroom
                </button>
                <button onClick={() => {window.open('https://bytesized-group.slack.com/?redir=%2Fgantry%2Fclient')}} className="text-white bg-purple-500 hover:bg-purple-600 p-2 rounded-full ">
                  {/* Icon placeholder */} Slack
                </button>
              </div>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="w-full md:flex-grow p-4 space-y-8">
            
            <div className="bg-white p-6 rounded-2xl shadow-md flex items-center space-x-6 scale-hover-05">
                <div className="text-blue-400 w-16 h-16 rounded-full flex items-center justify-center bg-amber-100 p-3">
                  {/* Placeholder for an Icon */}
                </div>
                <div>
                  <h2 className="text-xl font-semibold mb-2">Next Class</h2>
                  {/* Strikethrogh the past classes */}
                  <strong>Unknown</strong>
                </div>
              </div>
      
            <div className="bg-white p-6 rounded-2xl shadow-md flex items-center space-x-6 scale-hover-05">
              <div className="text-green-400 w-16 h-16 rounded-full flex items-center justify-center bg-green-100 p-3">
                {/* Placeholder for an Icon */}
              </div>
              <div>
                <h2 className="text-xl font-semibold mb-2">Assignments</h2>
              <Link to='my-assignments/'>
                <b>Go To Assignments Page</b>
              </Link>
              </div>
            </div>
              
            <div className='grid grid-cols-1 md:grid-cols-2 gap-12'>

              <div className='flex-1 justify-self-center self-center w-full scale-hover-05 '>
              <div className="bg-white rounded-lg shadow-2xl p-6 m-4 ">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-lg font-bold text-gray-700">Streak</p>
                    <p className="text-4xl font-semibold">{studentProfile.streak_count}</p>
                    <p className="text-sm text-gray-500">days</p>
                  </div>
                  <div className={`w-16 h-16 ${SuperUser ? 'bg-red-300' : 'bg-blue-300'}  rounded-full flex items-center justify-center`}>
                    <FaFire className='scale-150' /> {/* This uses Font Awesome for the fire icon */}
                  </div>
                </div>
            </div>
                
              </div>


              <div className='scale-hover-05'>
                <div className="w-full flex flex-col items-center p-6 bg-white rounded-lg shadow-md ">
                  {/* Verification Icon - You can use a different icon or image */}
                  <div className="w-16 h-16 mb-4 bg-green-200 rounded-full flex items-center justify-center">
                    <svg
                      className="w-10 h-10 text-green-700"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M5 13l4 4L19 7"></path>
                    </svg>
                  </div>
                  
                  {/* Verification Title */}
                  <h2 className="mb-2 text-xl font-semibold">Verification?</h2>
                  
                  {/* Description - Can be customized */}
                  <p className="mb-4 text-center text-gray-600">
                    Need a verification code? Click the button below to access it.
                  </p>
                  
                  {/* Link to the Verification page */}
                  <Link to='/verification'><span className="text-blue-600 hover:underline">Go to Verification Page</span></Link>
                </div>
              </div>

            </div>

          </div>
        </div>
      </>
        
      :
      <>
        <div className="flex justify-center items-center h-screen">
            <LoadingSpinner />
        </div>
      </>
  
      }
    </>

  );
}







