import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory} from 'react-router-dom';
import SideBar from '../SideBar';
import MobileHeader from '../headers/MobileHeader';
import DesktopHeader from '../headers/DesktopHeader';
import { useDispatch, useSelector } from 'react-redux';


import { setAssignments } from '../../redux/reducers/assignmentSlice';
import { setCourses, setStatus } from '../../redux/reducers/coursesSlice';
import { setBytes } from '../../redux/reducers/bytesSlice';
import { setProfile } from '../../redux/reducers/profileSlice';
import Login from '../../pages/Login';
import LoadingSpinner from '../spinner/LoadingSpinner';
import { setMeetings } from '../../redux/reducers/meetingsSlice';



export default function Homepage({route, PageContent, authNeeded=true, ...props}) {
    const [isOpen, setIsOpen] = useState(false);
    const REQUEST_COUNT_KEY = 'requestCount';
    const LAST_REQUEST_KEY = 'lastRequestTimestamp';
    const REQUEST_LIMIT = 400;  // e.g., 5 requests
    const TIME_FRAME = 5 * 60 * 1000;  // 10 seconds ONLY FOR DEMO PURPOSES
    const dispatch = useDispatch();
    const assignments = useSelector(state => state.assignments);
  
    
    let [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  
    const [isAuthenticated, setIsAuthenticated] = useState(false); // start with null to indicate "loading" or "unknown" state
    const [loadingAuth, setLoadingAuth] = useState(true);


  
    const topRef = useRef(null);
    const history = useHistory();
  
    const sideBarSized = useCallback(() => {
      if (!isMobile() && !isOpen) {
        setIsOpen(true);
      }
      else if (isMobile() && isOpen) {
        setIsOpen(false);
      }
    }, [isOpen]);


    useEffect(() => {
      if (topRef.current) {
        topRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, [PageContent]);
  
    useEffect(() => {
    
      if (!isOpen) {
        sideBarSized(); // Run your function
      }

      

  }, [isOpen, sideBarSized]);
    
  
  useEffect(() => { 
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/set-csrf-token/`, {
          method: "GET",
          credentials: "include",  // Important
        });


        if (response.status === 401) {
          // Silently handle the 401 without throwing or logging
          
          setIsAuthenticated(false);
          return;
        } else if (!response.ok) {
          // Handle other unexpected status codes without logging
          // Do any additional handling you might need here
        } else {
          checkAuthentication();
        }
      } catch (error) {
        console.error(error);
      }
    }
    
  fetchData();
  }, []);
    
    
  
    
    
  
  
  
    async function checkAuthentication() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/verify-token/`, {
          credentials: 'include',
          method: 'GET',
        });
        if (!response.ok) {
          setIsAuthenticated(false);
          return;
       }
       const responseData = await response.json();

        if (response.status === 401) {
          setIsAuthenticated(false);
          return;
       } else
  
        if (responseData.valid) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoadingAuth(false);
      }
  }


    useEffect(() => {
      if (route === '404') {
        setBackgroundColor('#1A2238');
      }
      else if (route === 'home') {
        setBackgroundColor('#f5f7fa');
      }
      else {
        setBackgroundColor('#f5f7fa');
      }
      // ... add conditions for other routes if needed
    }, [route]);


  
  
    const canFetchData = useCallback(() => {
      const now = new Date().getTime();
      const lastRequestTimestamp = parseInt(localStorage.getItem(LAST_REQUEST_KEY) || "0");
      const requestCount = parseInt(localStorage.getItem(REQUEST_COUNT_KEY) || "0");
    
      // If outside the time frame, reset the count
      if (now - lastRequestTimestamp > TIME_FRAME) {
        localStorage.setItem(REQUEST_COUNT_KEY, "0");
        return true;
      }
    
      // If within the time frame and under the limit, allow fetching
      if (requestCount < REQUEST_LIMIT) {
        return true;
      }
    
      // If within the time frame and over the limit, disallow fetching
      console.log("Request limit reached");
      //SETIDLE(true);
      //alert("Request limit reached, please wait for a moment while the server recovers.");
      return false;
    }, [TIME_FRAME]);
  
    function updateRequestCount() {
      const now = new Date().getTime();
      const requestCount = parseInt(localStorage.getItem(REQUEST_COUNT_KEY) || "0");
      localStorage.setItem(REQUEST_COUNT_KEY, (requestCount + 1).toString());
      localStorage.setItem(LAST_REQUEST_KEY, now.toString());
  }
  







function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}



  const getAuthTokenFromCookie = useCallback(() => {
    return getCookie('auth_token');
  }, []);

const signin = async (username, password) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api-token-auth/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': getCookie('csrftoken')
          },
          body: JSON.stringify({ username, password }),
          credentials: 'include',  // Important
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.token) {
          // Handle successful authentication
          if (history.location.pathname === '/login') {
            history.push('/home');
           }
          setIsAuthenticated(true);
          return { success: true };
      } else {
          console.error("Authentication failed!");
          return { success: false, message: "Authentication failed!" };
      }
  } catch (error) {
      console.error("There was a problem with the fetch operation:", error.message);
      return { success: false, message: error.message };
  }
}




  
  
  
    async function markAsComplete(assignment_id) {
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student-assignments/${assignment_id}/complete/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${getAuthTokenFromCookie()}`
            },
            credentials: 'include',  // Important
          });
        
          if (response.status === 401) { // Check for 401 status
            await checkAuthentication();
            if (!isAuthenticated) { // Check if still not authenticated after verification
                history.push('/login/');
                throw new Error('Authentication failed.');
            }
            // If authentication is verified, you may choose to repeat the fetch or handle as needed.
            throw new Error('Other authentication error'); 
          }
  
          if (!response.ok) {
              throw new Error('Network response was not accessable');
          }
      } catch (error) {
          console.error("There was a problem with the fetch operation:", error.message);
      }
  }













  const getAssignments = useCallback(() => {
    if (!canFetchData()) return assignments;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/my-assignments/`, {
        credentials: 'include',
        headers: {
            'Authorization': `Token ${getAuthTokenFromCookie()}`
        },
    })
    .then(async response => {
        if (response.status === 401) { // Check for 401 status
            await checkAuthentication();
            if (!isAuthenticated) { // Check if still not authenticated after verification
                history.push('/login/');
                throw new Error('Authentication failed.');
            }
            // If authentication is verified, you may choose to repeat the fetch or handle as needed.
            throw new Error('Other authentication error'); 
        }
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        // Update state
        dispatch(setAssignments(data));
        
        // Store timestamp of last fetch
        updateRequestCount();
    })
    .catch(error => {
        console.error('There was a problem with the fetch operation:', error.message);
    });
}, [dispatch, assignments, history, isAuthenticated, canFetchData, getAuthTokenFromCookie]);



  const getCourses = useCallback(() => {
      dispatch (setStatus('loading'));
      fetch(`${process.env.REACT_APP_BACKEND_URL}/my-courses/`, {
        credentials: 'include',
        headers: {
          'Authorization': `Token ${getAuthTokenFromCookie()}`
        },
        
      
      })
      
        .then(async response => {
            if (response.status === 401) { // Check for 401 status
              await checkAuthentication();
              if (!isAuthenticated) { // Check if still not authenticated after verification
                  history.push('/login/');
                  throw new Error('Authentication failed.');
              }
              // If authentication is verified, you may choose to repeat the fetch or handle as needed.
              throw new Error('Other authentication error'); 
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // 3. Update state
            dispatch(setCourses(data));

            
            
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.message);
            dispatch(setCourses([]));
        });
}, [dispatch, history, isAuthenticated, getAuthTokenFromCookie]);
  

  const getProfile = useCallback(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/get-profile/`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    
      .then(async response => {
          if (response.status === 401) { // Check for 401 status
            await checkAuthentication();
            if (!isAuthenticated) { // Check if still not authenticated after verification
                history.push('/login/');
                throw new Error('Authentication failed.');
            }
            // If authentication is verified, you may choose to repeat the fetch or handle as needed.
            throw new Error('Other authentication error'); 
          }
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          // 3. Update state
          dispatch(setProfile(data));
          
      })
      .catch(error => {
          console.error('There was a problem with the fetch operation:', error.message);
          dispatch(setProfile([]));
      });
}, [dispatch, history, isAuthenticated]);
    

    
  const getBytes = useCallback((courseId) => {
      //dispatch (setByteStatus('loading'));
      fetch(`${process.env.REACT_APP_BACKEND_URL}/courses/${courseId}/bytes/`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${getAuthTokenFromCookie()}`
        },
      })
      
        .then(async response => {
            if (response.status === 401) { // Check for 401 status
              await checkAuthentication();
              if (!isAuthenticated) { // Check if still not authenticated after verification
                  history.push('/login/');
                  throw new Error('Authentication failed.');
              }
              // If authentication is verified, you may choose to repeat the fetch or handle as needed.
              throw new Error('Other authentication error'); 
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // 3. Update state
            
            dispatch(setBytes(data));


            
            
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.message);
            dispatch(setBytes([]));
        });
}, [dispatch, history, isAuthenticated, getAuthTokenFromCookie]);
  

  const get_meetings = useCallback(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/my-meetings`, {
      credentials: 'include',
      headers: {
        'Authorization': `Token ${getAuthTokenFromCookie()}`
      },

    })
      
      .then(async response => {
        if (response.status === 401) { // Check for 401 status
          await checkAuthentication();
          if (!isAuthenticated) { // Check if still not authenticated after verification
            history.push('/login/');
            throw new Error('Authentication failed.');
          }
          // If authentication is verified, you may choose to repeat the fetch or handle as needed.
          throw new Error('Other authentication error');
        }
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // 3. Update state
            
        dispatch(setMeetings(data));
            

            
            
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error.message);
        dispatch(setBytes([]));
      });
}, [dispatch, history, isAuthenticated, getAuthTokenFromCookie]);
    


useEffect(() => {
  if (isAuthenticated) {
    getAssignments();
    getCourses();
    getProfile();
    get_meetings();
  }
  // If these functions should only be created once, pass an empty array as dependencies to useCallback
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isAuthenticated]); // Only re-run when isAuthenticated changes
  
    // Function check if the device is mobile
    function isMobile() {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return width < 768; // 768 is the standard width for mobile devices
    }

    // Listen to window resize event
  window.addEventListener("resize", () => sideBarSized());
  
    // Function to check if sidebar should be open or closed
  
    
  // Function to show header component depending on device
  function showHeader() { 
    if (isMobile()) {
      return <>
        <MobileHeader isOpen={isOpen} setIsOpen={setIsOpen} isAuth={isAuthenticated} />

      </>
    } else {
      return <DesktopHeader isAuth={isAuthenticated} />
    }
  }



  
    return (
      <>
        
        {/* Show header depending on device */}
        <div ref={topRef} style={{ backgroundColor: `${backgroundColor}` }}>

          {showHeader()}
        </div>

        <div className='home-screen flex justify-center '>
          {/* Hide sidebar */}
          
          {isAuthenticated &&
            <div className='ml-64'>
              <SideBar isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} isAuth={isAuthenticated} />
            </div>
          }
          {/* Main content area */}
          {loadingAuth ? 
            <LoadingSpinner />  // Or any loading spinner/component you have
            : authNeeded ?
                (isAuthenticated ? 
                    PageContent(getBytes, getAssignments, signin, setIsAuthenticated, markAsComplete) 
                : <Login signin={signin} setIsAuthenticated={setIsAuthenticated} currentPage={props.location} />
                )
                : PageContent(getBytes, getAssignments, signin, setIsAuthenticated, markAsComplete)
          }




          
          
          {/* {PageContent(getBytes, getAssignments, signin)} */}

        </div>

      </>
    );
}