import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../components/spinner/LoadingSpinner';
import { Link } from 'react-router-dom';

export default function CoursePage({getBytes, getAssignments}) {
  const { courseId } = useParams();
  const courses = useSelector(state => state.courses.courses);
  const [current, setcourse] = useState(null);
  const bytes = useSelector(state => state.bytes.bytes);
  const assignments = useSelector(state => state.assignments);


  useEffect(() => {
    if (courses) {
      const matchedCourse = courses.find(c => c.id === courseId);
      if (matchedCourse) {
        setcourse(matchedCourse);

        getBytes(courseId);
      

        if (!assignments) {
          getAssignments();
          
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, courseId]);

  const GradientBackground = ({ children }) => {
    return (
        <div className="bg-gradient-to-b w-screen flex justify-center from-[rgba(173,216,230,0.1)] via-[rgba(176,224,230,0.3)] to-[rgba(221,160,221,0.1)] min-h-screen">
            {children}
        </div>
    );
  };


  const CourseUnits = ({ bytes }) => {

    const [selectedUnit, setSelectedUnit] = useState(null);
   


    const gradeText = (grade) => {
    if (grade >= 95) {
      return "text-green-500";
    } else if (grade >= 80) {
      return "text-blue-500";
    } else if (grade >= 70) {
      return "text-yellow-500";
    } else if (grade >= 60) {
      return "text-yellow-700";
    } else if (grade >= 0) {
      return "text-red-500";
    }
    else if (grade === -1) {
      return "text-gray-500";
    } else {
      return "text-gray-500";
    }
    }
    
    const submissionStatus = (submissionStatus) => {
      if (submissionStatus === true) {
        return "Submitted";
      } else {
        return "";
      }
    }

    function getColorBasedOnDueDate(dueDate, turned_in) {
      const currentDate = new Date();
      const due = new Date(dueDate);

      if (turned_in) {
        return "";
      }
  
      // Calculate the difference in days between the current date and the due date
      const differenceInTime = due.getTime() - currentDate.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      // Return colors based on the difference in days
      if (differenceInDays <= 1) {
          return "text-red-500 underline"; // Due in 1 day or overdue
      } else if (differenceInDays <= 5) {
          return "text-orange-500 text-underline"; // Due in 3 days
      } else {
          return ""; // More than a week left
      }
  }
  

   
    const dropdownVariants = {
        open: { opacity: 1, height: "auto" },
        closed: { opacity: 0, height: 0 }
    };

    
  
  



    const byteButton = (byte, index) => {
      let num_of = assignments.filter(assignment => assignment.assignment_instance.byte === byte.id).length;
    if (byte.locked === false && num_of >= 1) {  // Changed || to && and > 1 to >= 1
        return (
          <button
            className="w-full text-left cursor-pointer p-4 flex justify-between items-center unit-hover"
            onClick={() => (setSelectedUnit(selectedUnit === index ? null : index))}>
            <span className="text-lg md:font-medium">{byte.name}</span>
            <span className="text-blue-500">View Lessons</span>
            
          </button>
        )
      } else {
          return (
            <>
            <button
              className="w-full text-left cursor-pointer p-4 flex justify-between items-center bg-gray-200"
              disabled={true}
              onClick={() => (setSelectedUnit(selectedUnit === index ? null : index))}>
              <span className="text-lg font-medium">{byte.name}</span>
              </button>
              </>
              )
            }
      }
      
      const formatDate = (dateString) => {
        if (!dateString) {
            return "Not Due";
        }
    
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    
        return utcDate.toLocaleDateString(undefined, options);
    }

    function truncateText(text, maxLengthMobile, maxLengthDesktop) {
      const isMobile = window.innerWidth <= 768; // You can adjust this value based on your mobile breakpoint
      const maxLength = isMobile ? maxLengthMobile : maxLengthDesktop;
    
      if (text.length <= maxLength) return text;
      return text.slice(0, maxLength) + '...';
    }
    
    

    
    return (
      <div className="p-4 sm:p-6 md:p-8 lg:p-10 unit justify-center min-w-[400px] md:min-w[500px] lg:min-w-[700px]">
        <div className="bg-white rounded-md shadow-md overflow-hidden">
          {bytes ?
            <ul>
              {bytes.map((byte, index) => (
                <li key={index}>
                  
                  {byteButton(byte, index)}
                  <AnimatePresence>

                    {selectedUnit === index && (
                      <motion.div
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={dropdownVariants}
                    >
                        {selectedUnit === index && (
                        <ul className="bg-gray-100">
                          
                          {assignments
                          .filter(assignment => assignment.assignment_instance.byte === byte.id)
                          .sort((a, b) => new Date(a.assignment_instance.due_date) - new Date(b.assignment_instance.due_date))
                          .map((assignment, lessonIndex) => (
                            <li key={lessonIndex} className="border-t p-3 flex justify-between items-center text-center unit-hover" >
                              {assignment.locked ? <p className='opacity-40'>{truncateText(assignment.assignment_instance.template.name, 14, 30)}</p> : 
                              <Link to={`../assignment/${assignment.id}`} className="text-blue-500 hover:underline">{truncateText(assignment.assignment_instance.template.name, 14, 30)}</Link>
                              }
                              {/* <a href={assignment.name} className="text-blue-500 hover:underline">{assignment.assignment_instance.template.name}</a> */}
                              <div className="text-sm">
                                
                                <p className={`${gradeText(assignment.grade)}`}>{`Grade: ${assignment.grade === -1 ? 'Awaiting' : assignment.grade}%`}</p>
                                <p className={`${getColorBasedOnDueDate(assignment.assignment_instance.due_date, assignment.turned_in)}`}>{`Due: ${formatDate(assignment.assignment_instance.due_date)}`}</p>
                                <p>
                                  Status:&ensp;
                                  {submissionStatus(assignment.turned_in) 
                                    ? submissionStatus(assignment.turned_in) 
                                    :
                                    <span className='text-red-500 underline'>Not Submitted</span>}
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}

                    </motion.div>
                    )} 
                  </AnimatePresence>
                </li>
              ))}
            </ul>
          : <LoadingSpinner />}
        </div>
      </div>
    );
  };

  



  return (
    <GradientBackground>
        <div className='flex flex-col items-center h-screen'>
            {current ? (
                <>
                    <h1 className='font-bold text-slate-700 pt-3 text-3xl sm:text-4xl text-center md:text-6xl' style={{ fontFamily: 'Arvo', overflowWrap: 'break-word' }}>{current.name}</h1>
                    {bytes && bytes.length > 0 ? (
                        <CourseUnits bytes={bytes} />
                    ) : (
                        <div className='font-bold text-slate-700 pt-3 text-xl text-center'>
                            You have no Bytes :(
                            <br />Try reloading..
                        </div>
                    )}
                </>
            ) : <LoadingSpinner />}
        </div>
    </GradientBackground>
);

}
