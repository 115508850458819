import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function CourseCard({ course, courseName, courseDescription, courseImage }) {
    return (
        <div className="card mb-4 scale-hover-05">
            <img src={'https://coolwallpapers.me/picsup/3085038-close-up_code_coding_computer_css_data_developer_developing_development_display_electronics_focus_keyboard_laptop_programmer_programming_screen_technology.jpg'}  alt={courseName} className="course-image blur-sm" />
            <div className="card-body">
                <h5 className="card-title">{courseName}</h5>
                <p className="card-text">{courseDescription}</p>
                <br />
                <Link to={`/courses/${course.id}`}>
                    <button className="btn btn-primary">View Course</button>
                </Link>
                
            </div>
        </div>
    );
}

export default CourseCard;
