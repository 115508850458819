import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaEdit, FaLock } from 'react-icons/fa';



const Calendar = () => {
    const [days, setDays] = useState([]);
    const currentDateRef = useRef(null);

    const All_Assignments = useSelector(state => state.assignments);
    const ALL_Meetings = useSelector(state => state.meetings);

    const [assignments, setAssignments] = useState([]);
    const [meetings, setMeetings] = useState([]);

    useEffect(() => {
        if (All_Assignments) {
            // console.log("All_Assignments:dfsdfsdfsdf ", All_Assignments[1].assignment_instance.due_date)
            setAssignments(All_Assignments);
        }
        if (ALL_Meetings) {
            // console.log("ALL_Meetings: ", ALL_Meetings)
            setMeetings(ALL_Meetings);
        }
    }, [All_Assignments, ALL_Meetings]);
    
    useEffect(() => { 
        if (!assignments) { 
            setAssignments([]);
        }
    }, [assignments]);

    useEffect(() => {
        generateDays();
    }, []);
    
    useEffect(() => {
        let scrollInterval;
        
        const scrollToCurrentDate = () => {
            if (currentDateRef.current) {
                const scrollToCurrentDate = () => {
                    if (currentDateRef.current) {
                        currentDateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        clearInterval(scrollInterval);
                    }
                };
                scrollToCurrentDate();
                clearInterval(scrollInterval);
            }
        };
    
        scrollInterval = setInterval(scrollToCurrentDate, 100);
    
        return () => {
            clearInterval(scrollInterval);
        };
    }, []);
    
    
    
    
    

    const generateDays = () => {
        const today = new Date();

        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() - 7); // Subtracting 7 moves us back one week.


        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 27); // 4 weeks in total, 28 days, but minus one since both start and end days are inclusive.


        let days = [];

        const totalDays = (endOfWeek - startOfWeek) / (1000 * 60 * 60 * 24); // Calculates the difference in days

        for(let i = 0; i <= totalDays; i++) {
            const newDate = new Date(startOfWeek);
            newDate.setDate(startOfWeek.getDate() + i);
            days.push(newDate);
        }


        setDays(days);

    };

    function isOverdue(assignment) {

        const now = new Date();
        const due = new Date(assignment.assignment_instance.due_date);

        if (assignment.turned_in) { 
            return false;
        }
        return Boolean(due < now)
    }

    // const assignments = {
    //     "2023-10-25": ["Go to the CS50 lecture!"],
    //     "2023-10-26": ["History Essay"],
    //     "2023-10-18": ["History Essay 2"],
    //     // ... (add more dates and assignments as needed)
    // };

    // const classes = {
    //     "2023-10-25": ["Lecture - 3PM"],
    //     "2023-10-26": ["History - 11AM"],
    //     // ... (add more dates and classes as needed)
    // };

    

    const isMediumScreen = window.innerWidth > 750 && window.innerWidth < 950;
    
    const containerStyle = isMediumScreen ? { gridTemplateColumns: 'repeat(5, 1fr)', gap: '4px' } : {};

    return (
        <div className="p-4 bg-gray-100 rounded-lg hover:shadow-xl transition-all duration-300 mx-auto w-5/6 xl:max-w-3/4">
            {/* Weekday Headers */}
            <div className="hidden md:grid grid-cols-7 gap-2 mb-4">
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
                    <div key={index} className="text-gray-500 text-center font-semibold">{day}</div>
                ))}
            </div>

            {/* Days */}
            <div className="grid grid-cols-1 gap-4 md:grid-cols-7 lg:gap-6" style={containerStyle}>
                {days.map((day, index) => {

                    const today = new Date();
                    const isToday = today.toDateString() === day.toDateString();
                    const isCurrentMonth = today.getMonth() === day.getMonth();

                    const dayStr = `${day.getFullYear()}-${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`;
                    const dayAssignments = assignments.filter(assignment => assignment.assignment_instance.due_date === dayStr);
                    //const dayClasses = meetings.filter(meeting => new Date(meeting.start_time).toDateString() === new Date(dayStr).toDateString()) || [];
                    const dayClasses = meetings.filter(meeting => {
                        const meetingDate = new Date(meeting.start_time);
                        const meetingDay = meetingDate.getDate();
                        const meetingMonth = meetingDate.getMonth();
                        const meetingYear = meetingDate.getFullYear();
                    
                        const dayDate = new Date(dayStr);
                        dayDate.setHours(dayDate.getHours() + 5); // add 5 hours to adjust for GMT-0500 
                        
                    
                        return meetingDay === dayDate.getDate() && meetingMonth === dayDate.getMonth() && meetingYear === dayDate.getFullYear();
                    }) || [];
                    
                    function formatClassTime(meeting) {
                        const date = new Date(meeting.start_time);
                    
                        const hours = date.getHours();
                        const minutes = date.getMinutes();
                        const ampm = hours >= 12 ? 'PM' : 'AM';
                        let formattedHour = hours % 12;
                        if (formattedHour === 0) formattedHour = 12;
                    
                        const formattedMinutes = minutes === 0 ? '' : `:${minutes.toString().padStart(2, '0')}`;
                        return `${meeting.title} - ${formattedHour}${formattedMinutes}${ampm}`;
                    }

                    return (
                        <div
                            key={dayStr} // add a unique key prop
                            ref={isToday ? currentDateRef : null}
                            className={`flex flex-col space-y-1 p-3 rounded-lg transform transition-all duration-300 
                                ${isToday ? 'bg-blue-100 border border-blue-300' : (isCurrentMonth ? 'bg-white shadow-md hover:shadow-xl hover:-translate-y-1' : 'bg-gray-200 text-gray-400')}
                            `}
                        >
                            {/* Date */}
                            <div className={`text-center font-semibold ${isToday ? 'text-blue-600' : (isCurrentMonth ? 'text-gray-700' : 'text-gray-400')}`}>
                                {day.getDate()}
                            </div>

                            {/* Assignments and Classes */}
                            <div className="flex-grow gap-y-1">
                                {dayAssignments.length === 0 && dayClasses.length === 0 ? (
                                    <span className="opacity-20 text-xs flex text-center justify-center items-center">
                                        <FaEdit />
                                        <p>&nbsp;No Events</p>
                                    </span>
                                ) : (
                                    <>
                                        {/* Assignments */}
                                        {dayAssignments.map((assignment, assignmentIndex) => (
                                            !assignment.locked ?
                                                <Link key={assignment.id} to={`assignment/${assignment.id}`}> {/* add a unique key prop */}
                                                    <div className={`${isOverdue(assignment) ? 'bg-red-300' : 'bg-blue-300 text-blue-800'}   px-3 py-2 rounded text-center mb-2 shadow text-xs ${assignment.turned_in && 'opacity-40'}`}>
                                                        {assignment.assignment_instance.template.name}
                                                    </div>
                                                </Link>
                                            :   <div 
                                                            key={assignment.id} // add a unique key prop
                                                            className={`${isOverdue(assignment) ? 'bg-red-300' : 'bg-blue-300 text-blue-800'} px-3 py-2 rounded text-center mb-2 shadow text-xs opacity-30 relative`}
                                                    >
                                                            <FaLock 
                                                                className="absolute inset-0 m-auto w-5 h-5 opacity-100"  // The w-5 and h-5 set the size of the icon
                                                                aria-label="Locked"
                                                            />
                                                            {assignment.assignment_instance.template.name}
                                                    </div>
                                        ))}

                                        {/* Classes */}
                                        {dayClasses.map((cls, classIndex) => (
                                            <div key={classIndex} className={`bg-green-300 text-green-800 px-3 py-2 rounded text-center mb-2 shadow text-xs ${cls.finished ? 'opacity-30' : ''}`}>
                                                {formatClassTime(cls)}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
    
    
    
    
    
    
};

export default Calendar;
