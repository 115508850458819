import { configureStore } from '@reduxjs/toolkit';
import assignmentReducer from '../reducers/assignmentSlice';
import coursesReducer from '../reducers/coursesSlice';
import bytesSlice from '../reducers/bytesSlice';
import profileSlice from '../reducers/profileSlice';
import meetingsSlice from '../reducers/meetingsSlice';

const store = configureStore({
    reducer: {
        assignments: assignmentReducer,
        courses: coursesReducer,
        bytes: bytesSlice,
        profile: profileSlice,
        meetings: meetingsSlice,
    }
});

export default store;
