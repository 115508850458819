import { createSlice } from '@reduxjs/toolkit';

const assignmentSlice = createSlice({
    name: 'assignments',
    initialState: [],  // Ensure this is an empty array
    reducers: {
        setAssignments: (state, action) => {
            return action.payload;
        },
        turnInAssignment: (state, action) => {
            const assignmentToTurnIn = state.find(assignment => assignment.id === action.payload.assignmentId);
            if (assignmentToTurnIn) {
                assignmentToTurnIn.turned_in = true;
            }
        }
    }
});

export const { setAssignments, turnInAssignment } = assignmentSlice.actions;
export default assignmentSlice.reducer;
