import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../components/spinner/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { clearProfile } from '../redux/reducers/profileSlice';

export default function Logout({setIsAuthenticated}) {

    const history = useHistory();
    // const user = useSelector(state => state.profile);
    const dispatch = useDispatch();

    async function logout() {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/logout/`, {
                method: 'POST',
                credentials: 'include',  // Important
            });
    
            if (response.ok) {
                console.log("Logout successful!");
                dispatch(clearProfile());
                if (setIsAuthenticated) {
                    setIsAuthenticated(false);  // Update the authentication state
                }
                history.push('/');    // Redirect to login page
            } else {
                console.error("Logout failed!");
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:");
        }
    }
    
    useEffect(() => {
        logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LoadingSpinner />
    )
}
