import { createSlice } from '@reduxjs/toolkit';

const coursesSlice = createSlice({
    name: 'courses',
    initialState: {
        courses: [],
        status: 'idle',
        isLoading: false,
        error: null
    },
    reducers: {
        setCourses: (state, action) => {
            state.isLoading = false;
            state.courses = action.payload;
        },
        setStatus: (state, action) => {
            state.isLoading = true;
            state.status = action.payload;
        },
        setError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { setCourses, setStatus, setError } = coursesSlice.actions;
export default coursesSlice.reducer;