import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import formatDate from '../functions/formatDate';

const Assignments = () => {
    const assignments = useSelector(state => state.assignments);
    const history = useHistory();
    // Sample data for assignments (hardcoded)


    
    
    // const assignments = [
    //     {
    //         id: 1,
    //         name: "Assignment 1",
    //         dueDate: "October 15, 2023",
    //         grade: "Not Graded",
    //         details: "Detailed description about assignment 1...",
    //         isTurnedIn: false,
    //         link: "/assignments/1"
    //     },
    //     {
    //         id: 2,
    //         name: "Assignment 2",
    //         dueDate: "November 5, 2023",
    //         grade: "A",
    //         details: "Detailed description about assignment 2...",
    //         isTurnedIn: false,
    //         link: "/assignments/2"
    //     },
    //     // ... add more assignments as needed
    //     ];


    // return (
    //     <div className="p-4 w-full max-w-5xl mx-auto mt-10 md:mt-0">
    //       <h1 className="text-2xl md:text-4xl mb-4 md:mb-6 font-bold text-slate-700">Your Assignments</h1>
    //       <AnimatePresence>
    //         {assignments.map(assignment => (
    //           <motion.div
    //             key={assignment.id}
    //             className="bg-white rounded-lg shadow-md mb-4 md:mb-6 p-4 md:p-6 cursor-pointer" // Increased the rounding here with rounded-lg
    //             initial="hidden"
    //             animate="show"
    //             exit="exit"
    //             variants={itemVariants}
    //             whileHover={{ scale: 1.05 }}
    //             onClick={() => window.location.href = assignment.link}
    //           >
    //             <h2 className="text-xl md:text-2xl font-medium">{assignment.name}</h2>
    //             <p className="mt-2 text-gray-600 text-sm md:text-base">{assignment.details}</p>
    //             <div className="flex flex-col md:flex-row justify-between mt-4">
    //               <div className="mb-2 md:mb-0">
    //                 <p className="text-sm">Due: {assignment.dueDate}</p>
    //                 <p className="text-sm mt-2">Grade: {assignment.grade}</p>
    //               </div>
    //               <Link to={assignment.link} className="text-blue-500 hover:underline text-sm md:text-base">View Assignment</Link>
    //             </div>
    //           </motion.div>
    //         ))}
    //       </AnimatePresence>
    //     </div>
    //     );

    const go_to_assignment = (assignment_id) => {
        const assignment = assignments.find(a => a.id === assignment_id);
        if (assignment && !assignment.locked) {
            history.push(`/assignment/${assignment_id}`);
        }
    }

    const gradeText = (grade) => {
        if (grade === "A") {
          return "text-green-500";
        } else if (grade === "B") {
          return "text-blue-500";
        } else if (grade === "C") {
          return "text-yellow-500";
        } else if (grade === "D") {
          return "text-yellow-700";
        } else if (grade === "F") {
          return "text-red-500";
        } else {
          return "text-gray-500";
        }
    };

    function getAssignmentTypeBadge(type) {
        let bgColor = "bg-gray-200";
        let textColor = "text-gray-800";

        switch (type.toLowerCase()) {
            case "homework":
                bgColor = "bg-blue-200";
                textColor = "text-blue-800";
                break;
            case "assignment":
                bgColor = "bg-green-200";
                textColor = "text-green-800";
                break;
            case "project":
                bgColor = "bg-yellow-200";
                textColor = "text-yellow-800";
                break;
            case "quiz":
                bgColor = "bg-orange-200";
                textColor = "text-orange-800";
                break;
            case "test":
                bgColor = "bg-red-200";
                textColor = "text-red-800";
                break;
            default:
                break;
        }
        

        return (
            <span className={`inline-block ${bgColor} ${textColor} rounded-full px-3 py-1 text-sm font-semibold mr-2`}>
                {(type).charAt(0).toUpperCase() + (type).slice(1) }
            </span>
        );
    }



    return (
        <div className="p-4 w-full max-w-5xl mx-auto mt-10 md:mt-0">
        <h1 className='pb-8 font-bold text-5xl flex justify-center text-center text-slate-700' style={{ fontFamily: 'Arvo' }}>My Assignments</h1>
            {[...assignments].sort((a, b) => {
                const dueDateA = new Date(a.assignment_instance.due_date);
                const dueDateB = new Date(b.assignment_instance.due_date);

                const isOverdueA = !a.turned_in && dueDateA < new Date();
                const isOverdueB = !b.turned_in && dueDateB < new Date();

                if (isOverdueA && !isOverdueB) return -1; // Overdue A comes first
                if (!isOverdueA && isOverdueB) return 1;  // Overdue B comes first

                if (isOverdueA && isOverdueB) {
                    return dueDateA - dueDateB; // Oldest overdue tasks first
                } 

                if (a.turned_in && !b.turned_in) return 1;  // A turned in, B not turned in, B comes first
                if (!a.turned_in && b.turned_in) return -1; // A not turned in, B turned in, A comes first

                return dueDateB - dueDateA; // Both not turned in, oldest due date first
            }).map(assignment => {
                const isOverdue = !assignment.turned_in && new Date(assignment.assignment_instance.due_date) < new Date();
                const shakeAnimation = isOverdue ? {
                    x: [0, -10, 10, -10, 10, 0],
                    transition: { duration: 1, repeat: 1, repeatType: 'reverse' }
                } : {};
    
                return (
                    <motion.div
                        key={assignment.id}
                        className={`bg-white rounded-lg shadow-md mb-4 md:mb-6 p-4 md:p-6 cursor-pointer ${assignment.locked ? "opacity-40" : ""} ${isOverdue ? "hover:border-2 hover:border-red-500" : ""}`}
                        animate={shakeAnimation}
                        whileHover={{
                            scale: 1.05,
                            boxShadow: isOverdue ? "0px 0px 15px rgba(255, 0, 0, 0.5)" : "0px 0px 15px rgba(0, 136, 255, 0.4)",
                        }}
                        onClick={() => go_to_assignment(assignment.id)}
                    >
                        <h2 className={`text-xl md:text-2xl font-medium ${isOverdue ? "text-red-500" : "text-black"}`}>
                            {assignment.assignment_instance.template.name}
                            &nbsp;
                            &nbsp;
                            {getAssignmentTypeBadge(assignment.assignment_instance.template.type)} {/* The Badge */}
                        </h2>
                        <p className="mt-2 text-gray-600 text-sm md:text-base">{assignment.assignment_instance.template.description ? <span dangerouslySetInnerHTML={{ __html: assignment.assignment_instance.template.description }} /> : <p className='opacity-40'>No description for this for this...</p>}</p>
                        <div className="flex flex-col md:flex-row justify-between mt-4">
                            <div className="mb-2 md:mb-0">
                                <p className="text-sm">Due: {formatDate(assignment.assignment_instance.due_date)}</p>
                                <p className="text-sm mt-2">Grade: {assignment.grade === -1 && assignment.turned_in === true ? <>Awaiting</> : <span className={`${gradeText(assignment.grade)}`}>{assignment.grade}</span>}%</p>
                            </div>
                            {assignment.locked ? <p className="text-red-500 text-sm md:text-base">Locked</p> :
                                <Link to={`../assignment/${assignment.id}`} className="text-blue-500 hover:underline text-sm md:text-base">View Assignment</Link>
                            }
                        </div>
                    </motion.div>
                );
            })}
        </div>
    );
    
    
    

}

export default Assignments;
