import React from 'react'
import CourseCard from './CourseCard';

export default function Courses({courses}) {
  return (
    <div style={{ fontFamily: 'Arvo' }} className="container">
            {courses.map(course => (
                    <CourseCard key={course.name} course={course} courseName={course.name} courseDescription={course.description} courseImage={course.image} />
                ))}
    </div>
  )
}
