import React from 'react'
import logo from '../../images/try.png'
import { FaSignInAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom';


export default function DesktopHeader({isAuth}) {
  return (
    <>
      <div className={`h-16 bg-gray-700 relative flex items-center justify-center ${isAuth && 'ml-64'}`} style={{fontFamily: 'Arvo'}}>  {/* ml-64!!!! */}
  
          <div className='absolute right-0 pr-5'>
            {!isAuth && (
              <Link to="/login" className="text-white hover:text-blue-400 transition">
                <FaSignInAlt size={24} />
              </Link>
            )}
          </div>
  
          <div className='flex space-x-2'>
            <img src={logo} alt="" className='w-10 h-10 scale-hover-10' />
            <Link to={`${isAuth ? '/home' : '/'}`}>
              <h1 className=' text-white font-bold text-4xl scale-hover-05'>ByteSized</h1>
            </Link>
          </div>
  
      </div>
    </>
  );
}
